import { Box, Button, Modal } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import styles from "./styles.module.scss";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useFlight from "../../../../../../../hooks/useFlight";
import FlightForm from "../FlightForm";
import SearchArea from "../SearchArea";

const ManualForm = ({
  isOpen,
  toggle,
  addFlight,
  selectedFlight,
  changeFlight,
  setSelectedFlight,
}) => {
  const {
    flight,
    addRoute,
    setRoutes,
    removeRoute,
    changePrice,
    changeRouteField,
    changeFlightType,
    clearData,
  } = useFlight({ selectedFlight });

  const onSave = () => {
    if (!flight?.routes[0]?.departureIataCode) return;

    if (!selectedFlight) addFlight({ ...flight, id: "manual" });
    else changeFlight({ ...flight, id: "manual" });

    toggle();
    clearData();
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    background: "#fff",
  };
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      className={styles.order_popup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.card} style={modalStyle}>
        <Box className={styles.header}>
          <h5>{selectedFlight ? "Edit manual flight" : "Add manual flight"}</h5>
          <CloseOutlinedIcon onClick={toggle} />
        </Box>
        <Box className={styles.content}>
          <Box display="flex" flexDirection="column" height={"100%"}>
            <SearchArea
              flightType={flight.flightType}
              changeFlightType={changeFlightType}
              changeRouteField={changeRouteField}
              setRoutes={setRoutes}
            />
            <FlightForm
              flightType={flight.flightType}
              routes={flight.routes}
              setRoutes={setRoutes}
              changeRouteField={changeRouteField}
              addRoute={addRoute}
              changePrice={changePrice}
              removeRoute={removeRoute}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          className={styles.footer}
        >
          <Button variant="outlinedSecondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            variant={
              !flight?.routes[0]?.departureIataCode
                ? "containedSecondary"
                : "containedSuccess"
            }
            disabled={!flight?.routes[0]?.departureIataCode}
            onClick={onSave}
          >
            <SaveOutlinedIcon
              style={{
                marginRight: "10px",
              }}
            />
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ManualForm;
