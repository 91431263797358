import React, { useEffect, useMemo, useCallback, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { NumericFormat } from "react-number-format";

import { Box } from "@mui/material";
import airportsData from "airport-codes/airports.json";
import styles from "./style.module.scss";
import ItineraryCard from "./ItineraryCard";
import { ArrowRightIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import TicketHeader from "./TicketHeader";
import { formatSegmentDuration } from "../../utils/date";

const calcLayover = (prevArrival, currentDeparture) => {
  const a = moment(prevArrival);
  const b = moment(currentDeparture);
  const hours = b.diff(a, "hours");
  const totalMins = b.diff(a, "minutes");
  const restMins = totalMins - hours * 60;
  return `${hours}h ${restMins}m`;
};

const formatCity = (iata) => {
  const ap = airportsData.find((item) => item.iata === iata);
  if (!ap) return iata;
  const city = ap.city || iata;
  const country = ap.country || "";
  return `${city}`;
  // return `${city}${country ? `, ${country}` : ""}`;
};

const TicketForPq = ({
  data,
  isActive,
  onlyRead = false,
  isRoundTrip,
  clientMode,
}) => {
  const [logosCache, setLogosCache] = useState({});

  const fetchAirlineLogo = useCallback(
    async (carrierCode) => {
      if (logosCache[carrierCode]) return;
      try {
        const response = await fetch(
          `https://api.api-ninjas.com/v1/airlines?iata=${carrierCode}`,
          {
            headers: {
              "X-Api-Key": "yY/cGjvpuzPG0QLxgyl14g==u1hjIuYEMjmmGZyw",
            },
          }
        );
        const json = await response.json();
        if (Array.isArray(json) && json.length > 0 && json[0].logo_url) {
          setLogosCache((prev) => ({
            ...prev,
            [carrierCode]: json[0].logo_url,
          }));
        }
      } catch (error) {
        console.error("Error fetching airline logo:", error);
      }
    },
    [logosCache]
  );

  const allSegments = useMemo(() => {
    if (!data?.itineraries) return [];
    return data.itineraries.flatMap((it) => it.segments);
  }, [data]);

  const globalLayovers = useMemo(() => {
    if (!allSegments.length) return [];
    const result = [];
    for (let i = 1; i < allSegments.length; i++) {
      const gap = calcLayover(
        allSegments[i - 1].arrival.at,
        allSegments[i].departure.at
      );
      const hours = +gap.split("h")[0];
      if (hours > 24) result.push("roundtrip");
      else result.push(gap);
    }
    if (isRoundTrip && result.length) {
      result[Math.floor(result.length / 2)] = "roundtrip";
    }
    return result;
  }, [allSegments, isRoundTrip]);

  useEffect(() => {
    if (!data?.itineraries) return;
    const carriers = new Set();
    data.itineraries.forEach((it) =>
      it.segments.forEach((seg) => carriers.add(seg.carrierCode))
    );
    carriers.forEach((cc) => fetchAirlineLogo(cc));
  }, [data, fetchAirlineLogo]);

  return (
    <>
      <TicketHeader />
      <div className={styles.ticketCard}>
        <Box
          className={classNames(styles.ticketBlock, {
            [styles.active]: isActive,
            [styles.onlyRead]: onlyRead,
          })}
        >
          {data?.itineraries?.map((itinerary, itineraryIndex) => {
            const firstSeg = itinerary.segments[0];
            const lastSeg =
              itinerary.segments[itinerary.segments.length - 1] || null;

            const originCityFull = firstSeg
              ? formatCity(firstSeg.departure.iataCode)
              : "";
            const destinationCityFull = lastSeg
              ? formatCity(lastSeg.arrival.iataCode)
              : "";

            return (
              <>
                <div
                  key={`itin-${itineraryIndex}`}
                  className={styles.itineraryBlock}
                >
                  <div className={styles.itineraryTopText}>
                    <h2>
                      {originCityFull} <ArrowRightIcon /> {destinationCityFull}
                    </h2>
                    <p>
                      Travel time: {formatSegmentDuration(itinerary?.duration)}
                    </p>
                  </div>

                  <ItineraryCard
                    data={data}
                    itinerary={itinerary}
                    itineraryIndex={itineraryIndex}
                    globalLayovers={globalLayovers}
                    logosCache={logosCache}
                  />
                </div>
                {!data?.itineraries[itineraryIndex - 1] &&
                data?.itineraries.length > 1 ? (
                  <div className={styles.roundtripDivider} />
                ) : null}
              </>
            );
          })}
        </Box>

        {clientMode ? (
          <div className={styles.priceBlock}>
            <p>
              <span>Adults 12+:</span>
              <NumericFormat
                value={data?.grossPrice?.total || data?.grossPrice || 0}
                displayType="text"
                decimalScale={2}
                thousandSeparator
                prefix="$"
              />
            </p>
            <p>
              <span>Children 2-12:</span>
              <NumericFormat
                value={
                  data?.grossChildPrice?.total || data?.grossChildPrice || 0
                }
                displayType="text"
                decimalScale={2}
                thousandSeparator
                prefix="$"
              />
            </p>
            <p>
              <span>Infants 0-2:</span>
              <NumericFormat
                value={
                  data?.grossInfantPrice?.total || data?.grossInfantPrice || 0
                }
                displayType="text"
                decimalScale={2}
                thousandSeparator
                prefix="$"
              />
            </p>
            <p>
              <span>Baggage Info:</span>
              <span>
                {" "}
                {data?.weightType
                  ? `${data?.weightValue} ${data?.weightType}`
                  : "NO"}
              </span>
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TicketForPq;
