import classNames from "classnames";
import SearchParams from "./SearchParams";
import styles from "./style.module.scss";

const SearchArea = ({ flightType, changeFlightType }) => {
  return (
    <div className={classNames(styles.container, "search-area")}>
      <SearchParams
        flightType={flightType}
        changeFlightType={changeFlightType}
      />
    </div>
  );
};

export default SearchArea;
