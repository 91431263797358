export const isurancePercentage = 9;

export const flightTypes = {
  ONE_WAY: "one_way",
  ROUND_TRIP: "round_trip",
  DIFFICULT_ROUTE: "difficult_route",
};

export const flightClasses = {
  BUSINESS: "business",
  ECONOM: "econom",
};

export const flightClassesArray = [
  {
    key: flightClasses.BUSINESS,
    value: "Business class",
  },
  {
    key: flightClasses.ECONOM,
    value: "Economy class",
  },
];

export const sources = [
  {
    label: "Instagram",
    value: "INSTAGRAM",
  },
  {
    label: "Telegram",
    value: "TELEGRAM",
  },
  {
    label: "Whatsapp",
    value: "WHATSAPP",
  },
  {
    label: "Facebook",
    value: "FACEBOOK",
  },
  {
    label: "Website",
    value: "WEBSITE",
  },
  {
    label: "Friend",
    value: "FRIEND",
  },
];

export const flightTypesArray = [
  {
    key: flightTypes.ONE_WAY,
    value: "One Way",
  },
  {
    key: flightTypes.ROUND_TRIP,
    value: "Round trip",
  },
  {
    key: flightTypes.DIFFICULT_ROUTE,
    value: "Multiple flights",
  },
];
export const flightTypesOption = [
  {
    value: flightTypes.ONE_WAY,
    label: "One Way",
  },
  {
    value: flightTypes.ROUND_TRIP,
    label: "Round trip",
  },
  {
    value: flightTypes.DIFFICULT_ROUTE,
    label: "Multiple flights",
  },
];

export const iataCodes = [
  {
    value: "CVG",
    key: "Cincinatti - CVG",
  },
  {
    value: "CLE",
    key: "Cleveland - CLE",
  },
  {
    value: "CMH",
    key: "Columbus - CMH",
  },
  {
    value: "RSW",
    key: "Ford Mayers - RSW",
  },
  {
    value: "MCO",
    key: "Orlando - MCO",
  },
  {
    value: "ATL",
    key: "Atalanta - ATL",
  },
  {
    value: "IAH",
    key: "Houston - IAH",
  },
  {
    value: "DFW",
    key: "Dallas - DFW",
  },
  {
    value: "BOS",
    key: "Boston - BOS",
  },
  {
    value: "BWI",
    key: "Baltimore - BWI",
  },
  {
    value: "ORD",
    key: "Chicago - ORD",
  },
  {
    value: "TAS",
    key: "Tashkent - TAS",
  },
  {
    value: "IST",
    key: "Istanbul - IST",
  },
  {
    value: "IAD",
    key: "Washington DC - IAD",
  },
  {
    value: "NYC",
    key: "New York - JFK / NYC",
  },
  {
    value: "EWR",
    key: "New York - EWR",
  },
  {
    value: "DYU",
    key: "Dyushanbe - DYU",
  },
  {
    value: "SKD",
    key: "Samarkand - SKD",
  },
  {
    value: "BHK",
    key: "Bukhara - BHK",
  },
  {
    value: "UGC",
    key: "Urgench - UGC",
  },
  {
    value: "NMA",
    key: "Namangan - NMA",
  },
  {
    value: "FEG",
    key: "Ferghana - FEG",
  },
  {
    value: "AZN",
    key: "Andijan - AZN",
  },
  {
    value: "LED",
    key: "Sankt Piterburg - LED",
  },
  {
    value: "DME",
    key: "Moscow (MOW) - DME",
  },
  {
    value: "VKO",
    key: "Moscow (MOW) - VKO",
  },
  {
    value: "ZIA",
    key: "Moscow (MOW) - ZIA",
  },
  {
    value: "SVO",
    key: "Moscow (MOW) - SVO",
  },
  {
    value: "DXB",
    key: "Dubai - DXB",
  },
  {
    value: "DWC",
    key: "Dubai - DWC",
  },
  {
    value: "HSA",
    key: "TURKISTAN - HSA",
  },
  {
    value: "BNA",
    key: "Nashville Tennese - BNA",
  },
  {
    value: "CAI",
    key: "Cairo - CAI",
  },
  {
    value: "GYD",
    key: "Heydar Aliyev In.Airport - GYD",
  },
  {
    value: "DTW",
    key: "Detroit Metropolitan Wayne County Airport - DTW",
  },
  {
    value: "BDL",
    key: "Bradley International Airport - BDL",
  },
  {
    value: "PEK",
    key: "Beijing - PEK",
  },
  {
    value: "LGA",
    key: "La Guardia - LGA",
  },
  {
    value: "OMS",
    key: "Omsk - OMS",
  },
  {
    value: "YYZ",
    key: "Toronto - YYZ",
  },
  {
    value: "ICN",
    key: "Seoul - ICN",
  },
  {
    value: "PHL",
    key: "Philadelphia - PHL",
  },
  {
    value: "PIT",
    key: "Pittsburgh - PIT",
  },
  {
    value: "SFO",
    key: "San Francisco - SFO",
  },
  {
    value: "LAX",
    key: "Los Angeles - LAX",
  },
  {
    value: "LCY",
    key: "London City Airport - LCY",
  },
  {
    value: "LHR",
    key: "London Heatrow Airport - LHR",
  },
  {
    value: "AMS",
    key: "Amsterdam - AMS",
  },
  {
    value: "ASB",
    key: "Ashqabat - ASB",
  },
  {
    value: "SSH",
    key: "Sharm El Sheikh - SSH",
  },
  {
    value: "SGN",
    key: "Ho Chi  Minh City - SGN",
  },
  {
    value: "CXR",
    key: "Cam Ranh - CXR",
  },
  {
    value: "MDW",
    key: "Midwey Airport - MDW",
  },
];

export const paymentTypes = {
  PENDING: "pending",
  PAID: "paid",
};

export const paymentTypesArray = [
  {
    key: paymentTypes.PENDING,
    value: "Pending",
  },
  {
    key: paymentTypes.PAID,
    value: "Paid",
  },
];

export const weightTypes = {
  PC: "pc",
  KG: "kg",
  NO: "no",
};

export const weightTypesArray = [
  {
    key: weightTypes.NO,
    value: "NO",
  },
  {
    key: weightTypes.KG,
    value: "KG",
  },
  {
    key: weightTypes.PC,
    value: "PC",
  },
];
export const airlineLogos = [
  "/airlines/AA.png",
  "/airlines/DL.png",
  "/airlines/EK.png",
  "/airlines/F9.png",
  "/airlines/UA.png",
  "/airlines/HY.png",
  "/airlines/LO.png",
  "/airlines/MS.png",
  "/airlines/NK.png",
  "/airlines/TK.png",
];

export const carrierCodes = [
  // Исходные авиакомпании:
  {
    key: "HY",
    value: "HY",
    label: "Uzbekistan Airways",
  },
  {
    key: "TK",
    value: "TK",
    label: "Turkish Airlines",
  },
  {
    key: "UA",
    value: "UA",
    label: "United Airlines",
  },
  {
    key: "OZ",
    value: "OZ",
    label: "Asiana Airlines",
  },
  {
    key: "EK",
    value: "EK",
    label: "Emirates",
  },
  {
    key: "B9",
    value: "B9",
    label: "Iran Airtour",
  },
  {
    key: "LO",
    value: "LO",
    label: "LOT Polish Airlines",
  },
  {
    key: "DP",
    value: "DP",
    label: "Pobeda",
  },
  {
    key: "NK",
    value: "NK",
    label: "Spirit Airlines",
  },
  {
    key: "MS",
    value: "MS",
    label: "EgyptAir",
  },
  {
    key: "HH",
    value: "HH",
    label: "Taban Air",
  },
  {
    key: "AA",
    value: "AA",
    label: "American Airlines",
  },
  {
    key: "DL",
    value: "DL",
    label: "Delta Air Lines",
  },
  {
    key: "KL",
    value: "KL",
    label: "KLM Royal Dutch Airlines",
  },
  {
    key: "4S",
    value: "4S",
    label: "Solar Cargo",
  },
  {
    key: "AI",
    value: "AI",
    label: "Air India",
  },
  {
    key: "WN",
    value: "WN",
    label: "Southwest Airlines",
  },
  {
    key: "9S",
    value: "9S",
    label: "Southern Air",
  },
  {
    key: "LH",
    value: "LH",
    label: "Lufthansa",
  },
  {
    key: "AF",
    value: "AF",
    label: "Air France",
  },
  {
    key: "BA",
    value: "BA",
    label: "British Airways",
  },
  {
    key: "QF",
    value: "QF",
    label: "Qantas",
  },
  {
    key: "SQ",
    value: "SQ",
    label: "Singapore Airlines",
  },
  {
    key: "CX",
    value: "CX",
    label: "Cathay Pacific",
  },
  {
    key: "AS",
    value: "AS",
    label: "Alaska Airlines",
  },
  {
    key: "AC",
    value: "AC",
    label: "Air Canada",
  },
  {
    key: "B6",
    value: "B6",
    label: "JetBlue Airways",
  },
  {
    key: "VS",
    value: "VS",
    label: "Virgin Atlantic",
  },
  {
    key: "EY",
    value: "EY",
    label: "Etihad Airways",
  },
  {
    key: "QR",
    value: "QR",
    label: "Qatar Airways",
  },
  {
    key: "CZ",
    value: "CZ",
    label: "China Southern Airlines",
  },
  {
    key: "MU",
    value: "MU",
    label: "China Eastern Airlines",
  },
  {
    key: "SU",
    value: "SU",
    label: "Aeroflot",
  },
  {
    key: "NH",
    value: "NH",
    label: "All Nippon Airways",
  },
  {
    key: "TG",
    value: "TG",
    label: "Thai Airways",
  },
  // Новые дополнительные авиакомпании:
  {
    key: "KE",
    value: "KE",
    label: "Korean Air",
  },
  {
    key: "AY",
    value: "AY",
    label: "Finnair",
  },
  {
    key: "IB",
    value: "IB",
    label: "Iberia",
  },
  {
    key: "AZ",
    value: "AZ",
    label: "ITA Airways",
  },
  {
    key: "EI",
    value: "EI",
    label: "Aer Lingus",
  },
  {
    key: "VY",
    value: "VY",
    label: "Vueling Airlines",
  },
  {
    key: "UX",
    value: "UX",
    label: "Air Europa",
  },
  {
    key: "SN",
    value: "SN",
    label: "Brussels Airlines",
  },
  {
    key: "FR",
    value: "FR",
    label: "Ryanair",
  },
  {
    key: "U2",
    value: "U2",
    label: "EasyJet",
  },
  {
    key: "DY",
    value: "DY",
    label: "Norwegian Air Shuttle",
  },
  {
    key: "W6",
    value: "W6",
    label: "Wizz Air",
  },
  {
    key: "PC",
    value: "PC",
    label: "Pegasus Airlines",
  },
  {
    key: "AK",
    value: "AK",
    label: "AirAsia",
  },
  {
    key: "VA",
    value: "VA",
    label: "Virgin Australia",
  },
  {
    key: "NZ",
    value: "NZ",
    label: "Air New Zealand",
  },
  {
    key: "CI",
    value: "CI",
    label: "China Airlines",
  },
  {
    key: "MF",
    value: "MF",
    label: "Xiamen Airlines",
  },
  {
    key: "ZH",
    value: "ZH",
    label: "Shenzhen Airlines",
  },
  {
    key: "SG",
    value: "SG",
    label: "SpiceJet",
  },
  {
    key: "VJ",
    value: "VJ",
    label: "VietJet Air",
  },
  {
    key: "VN",
    value: "VN",
    label: "Vietnam Airlines",
  },
  {
    key: "G3",
    value: "G3",
    label: "Gol Transportes Aéreos",
  },
  {
    key: "LA",
    value: "LA",
    label: "LATAM Airlines",
  },
  {
    key: "JY",
    value: "JY",
    label: "JetSMART",
  },
  {
    key: "EN",
    value: "EN",
    label: "Air Dolomiti",
  },
  {
    key: "EW",
    value: "EW",
    label: "Eurowings",
  },
  {
    key: "SV",
    value: "SV",
    label: "Saudi Arabian Airlines",
  },
  {
    key: "MK",
    value: "MK",
    label: "Air Mauritius",
  },
  {
    key: "A3",
    value: "A3",
    label: "Aegean Airlines",
  },
  {
    key: "LY",
    value: "LY",
    label: "El Al",
  },
  {
    key: "JU",
    value: "JU",
    label: "Air Serbia",
  },
  {
    key: "FB",
    value: "FB",
    label: "Bulgaria Air",
  },
  {
    key: "BT",
    value: "BT",
    label: "airBaltic",
  },
  {
    key: "RJ",
    value: "RJ",
    label: "Royal Jordanian",
  },
  {
    key: "F9",
    value: "F9",
    label: "Frontier Airlines",
  },
];
