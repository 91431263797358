import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { orderSections } from "../..";
import { DotIcon } from "../../../../../assets/icons";
import FRow from "../../../../../components/FRow";
import TSelect, { FSelect } from "../../../../../components/Select";
import { selectStyles } from "../../../../../components/Select/styles";
import { flightTypes } from "../../../../../consts/flights";
import {
  leadStatus,
  leadStatusArray,
  statusStyles,
  submitType,
} from "../../../../../consts/lead";
import useNoteAPI from "../../../../../hooks/api/useNoteAPI";
import useUsers from "../../../../../hooks/api/useUsersAPI";
import useFlight from "../../../../../hooks/useFlight";
import useLead from "../../../../../hooks/useLead";
import { useHook } from "../Flights/FlightPopup/SearchArea/useHook";
import FlightInfo from "./FlightInfo";
import FlightSegments from "./FlightSegment";
import Flights from "./Flights";
import LeadForm from "./LeadForm";
import NotePadMenu from "./NotepadModal";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { isUserAdmin, isUserSupervisor } from "../../../../../utils";

const Lead = () => {
  const navigate = useNavigate();
  const captureRef = useRef(null);
  const element = captureRef?.current;
  const { user } = useSelector((store) => store.auth);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectStyle, setSelectStyle] = useState(leadStatusArray[0]);
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 500);
  const isAdmin = isUserAdmin(user.role) || isUserSupervisor(user.role);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openNotePad = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    unregister,
  } = useForm({
    defaultValues: {
      lead: {
        status: leadStatus.ACTIVE,
      },
      segments: [],
    },
  });

  const {
    fields: segments,
    append: segmentAppend,
    remove: segmentRemove,
  } = useFieldArray({
    control,
    name: "segments",
    keyName: "key",
  });

  const customer_name = useWatch({
    control,
    name: "lead.first_name",
  });

  const userId = useWatch({
    control,
    name: "lead.user_id",
  });

  const lead_status = useWatch({
    control,
    name: "lead.status",
  });

  const customer_email = useWatch({
    control,
    name: "lead.email",
  });
  const { flight, setRoutes, changeFlightType, changeRouteField, clearData } =
    useFlight({ selectedFlight });

  const {
    flights,
    lead,
    addFlight,
    changeFlight,
    isConfirmed,
    addSegmentParams,
    addLeadInformations,
    handleCreateOrder,
    orderId,
    noteControl,
  } = useOutletContext();

  const {
    params,
    tickets,
    currentTicket,
    loader,
    selectTicket,
    changeFlightParams,
    addRouteParam,
    removeRouteParam,
    searchTicketsWithSegmentParams,
    setIncludedCarriers,
  } = useHook({ changeRouteField, setRoutes, flightType: flight.flightType });

  const { handleScreenshot } = useLead({
    element,
    currentTicket,
    customer_name,
    customer_email,
  });
  const onSave = () => {
    if (!flight?.routes[0]?.departureIataCode) return;
    if (!selectedFlight) {
      addFlight({ ...flight, id: "auto" });
    } else {
      changeFlight(
        {
          ...flight,
          id: "auto",
          grossPrice:
            currentTicket?.grossPrice?.total ||
            currentTicket?.grossPrice?.toString(),
          grossChildPrice:
            currentTicket?.grossChildPrice?.total ||
            currentTicket?.grossChildPrice?.toString(),
          grossInfantPrice:
            currentTicket?.grossInfantPrice?.total ||
            currentTicket?.grossInfantPrice?.toString(),
          nettPrice:
            currentTicket?.nettPrice?.toString() || currentTicket?.price?.total,
          nettChildPrice:
            currentTicket?.nettChildPrice?.toString() ||
            currentTicket?.nettChildPrice?.total,
          nettInfantPrice:
            currentTicket?.nettInfantPrice?.toString() ||
            currentTicket?.nettInfantPrice?.total,
        },
        selectedIndex
      );
      setEditMode(false);
      setSelectedFlight(null);
    }
    clearData();
  };

  const onSubmit = (values, event) => {
    event.preventDefault();
    const action = event.nativeEvent.submitter.value;

    if (action === submitType.SAVE_QUITE) {
      handleCreateOrder(values);
    } else {
      addLeadInformations(values);
      navigate(`../${orderSections.PASSENGERS}`);
    }
  };

  const handleNext = () => {
    navigate(`../${orderSections.PASSENGERS}`);
  };

  const { getNote } = useNoteAPI({
    orderId: orderId,
  });

  useEffect(() => {
    if (lead) {
      reset(lead);
      setSelectStyle(
        leadStatusArray.find((item) => item.value === lead?.lead?.status) ||
          leadStatusArray[0]
      );
    }
  }, [lead]);

  const { data } = useUsers({
    enabled: true,
    amount: 30,
    search: debouncedSearch,
  });

  const agentOptions = useMemo(() => {
    return data?.users?.rows?.map((item) => ({
      label: `${item.last_name} ${item.first_name}`,
      value: item?.id,
    }));
  }, [data]);

  const handleAssignAgent = (value) => {
    setValue("lead.user_id", value.value);
  };

  return (
    <form className={styles.lead} onSubmit={handleSubmit(onSubmit)}>
      <Box className={styles.left_side}>
        <Box height="100%" className={styles.lead_info}>
          <Typography variant="h4">Lead Information</Typography>
          <LeadForm
            control={control}
            setValue={setValue}
            isConfirmed={isConfirmed}
          />
        </Box>
      </Box>
      <Box
        className={styles.right_side}
        style={{
          pointerEvents: isConfirmed && "none",
          opacity: isConfirmed && "0.7",
        }}
      >
        <FlightInfo
          currentTicket={currentTicket}
          handleScreenshot={handleScreenshot}
          control={control}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedFlight={selectedFlight}
          setSelectedFlight={setSelectedFlight}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <div
          className={`${styles.flights} ${
            segments?.length && styles.scroll_flights
          }`}
        >
          <FlightSegments
            segments={segments}
            segmentAppend={segmentAppend}
            segmentRemove={segmentRemove}
            control={control}
            watch={watch}
            addSegmentParams={addSegmentParams}
            params={params}
            addRouteParam={addRouteParam}
            changeFlightParams={changeFlightParams}
            removeRouteParam={removeRouteParam}
            reset={reset}
            getValues={getValues}
            searchTicketsWithSegmentParams={searchTicketsWithSegmentParams}
            changeFlightType={changeFlightType}
            setValue={setValue}
            setIncludedCarriers={setIncludedCarriers}
            unregister={unregister}
          />

          <Flights
            tickets={tickets}
            selectTicket={selectTicket}
            currentTicket={currentTicket}
            isRoundTrip={flight.flightType === flightTypes.ROUND_TRIP}
            flights={flights}
            flight={flight}
            onSave={onSave}
            loader={loader}
            captureRef={captureRef}
            setSelectedFlight={setSelectedFlight}
            setSelectedIndex={setSelectedIndex}
            setEditMode={setEditMode}
            editMode={editMode}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </Box>
      <Box className={styles.lead_footer}>
        <Box className={styles.lead_footer_button}>
          <TSelect
            defaultOptions={leadStatusArray}
            menuPlacement="top"
            // isDisabled={isConfirmed}
            value={leadStatusArray.find(
              (item) => item.value === watch("lead.status")
            )}
            placeholder="Select lead status"
            onChange={(e) => {
              setValue("lead.status", e.value);
              setSelectStyle(e);
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                background: selectStyle?.background,
                color: selectStyle?.color,
                maxWidth: "190px",
                minWidth: "auto",
              }),
              ...statusStyles,
            }}
          />
          {isAdmin && orderId ? (
            <FSelect
              menuPlacement="top"
              value={agentOptions?.find((el) => el?.value === userId)}
              options={agentOptions}
              placeholder="Select agent"
              onChange={handleAssignAgent}
              styles={selectStyles}
              onInputChange={(e) => setSearch(e)}
              isClearable
            />
          ) : null}
          {lead_status === leadStatus.CANCELLED && (
            <FRow>
              <Controller
                name="lead.cancellation_reason"
                control={control}
                required
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter reason"
                    className={styles.input}
                    required
                  />
                )}
              />
            </FRow>
          )}
        </Box>
        {!isConfirmed && (
          <Box className={styles.save_button_box}>
            <Button onClick={handleClick} className={styles.note_button}>
              {!!getNote?.content && <DotIcon />} Notepad
            </Button>
            <Button
              className={styles.footer_button}
              type="submit"
              name="action"
              value={submitType.SAVE_CONTINUE}
            >
              SAVE & CONTINUE
            </Button>
            <Button
              className={styles.save_button}
              variant="contained"
              name="action"
              value={submitType.SAVE_QUITE}
              type="submit"
            >
              SAVE & QUIT
            </Button>
          </Box>
        )}
        {isConfirmed && (
          <Box className={styles.save_button_box}>
            <Button onClick={handleClick} className={styles.note_button}>
              Notepad
            </Button>
            <Button
              className={styles.save_button}
              variant="contained"
              name="action"
              value={submitType.SAVE_QUITE}
              type="submit"
            >
              SAVE & QUIT
            </Button>
            <Button
              className={styles.save_button}
              onClick={handleNext}
              variant="contained"
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
      <NotePadMenu
        open={openNotePad}
        anchorEl={anchorEl}
        onClose={handleClose}
        orderId={orderId}
        noteControl={noteControl}
      />
    </form>
  );
};

export default Lead;
