import { useMemo } from "react";
import { airlineLogos, carrierCodes } from "../../consts/flights";
import styles from "./style.module.scss";
import moment from "moment";
import airportsData from "airport-codes/airports.json";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { ReactComponent as DepartureFlightIcon } from "../../assets/icons/departure_plane.svg";
import { ReactComponent as ArrivalFlightIcon } from "../../assets/icons/arrival_plane.svg";

const formatDuration = (val) => {
  if (!val) return "";
  const withoutPT = val.replace("PT", "");
  const [hrs, mRaw] = withoutPT.split("H");
  const mins = mRaw?.replace("M", "");
  if (!hrs && !mins) return "";
  return hrs ? `${hrs}h${mins ? ` ${mins}m` : ""}` : `${mins}m`;
};

const formatCity = (iata) => {
  const ap = airportsData.find((item) => item.iata === iata);
  if (!ap) return iata;
  const city = ap.city || iata;
  const country = ap.country || "";
  return `${city}${country ? `, ${country}` : ""}`;
};

const ItineraryCard = ({
  data,
  itinerary,
  itineraryIndex,
  globalLayovers,
  logosCache,
}) => {
  const firstSegment = itinerary.segments[0];
  const airlineCode = firstSegment?.carrierCode || "";
  const flightDuration = useMemo(() => {
    if (itinerary.duration) {
      return formatDuration(itinerary.duration) + " in flight";
    } else {
      let totalH = 0,
        totalM = 0;
      itinerary.segments.forEach((seg) => {
        const str = seg.duration.replace("PT", "");
        const [h, m] = str.split("H");
        totalH += Number(h || 0);
        totalM += Number(m?.replace("M", "") || 0);
      });
      totalH += Math.floor(totalM / 60);
      totalM = totalM % 60;
      return `${totalH}h ${totalM}m in flight`;
    }
  }, [itinerary]);

  return (
    <div className={styles.itineraryBody}>
      <div className={styles.segmentsContainer}>
        {itinerary.segments.map((seg, segIndex) => {
          let globalIndex = 0;
          for (let k = 0; k < itineraryIndex; k++) {
            globalIndex += data.itineraries[k].segments.length;
          }
          globalIndex += segIndex;

          const layoverToShow =
            globalIndex > 0 ? globalLayovers[globalIndex - 1] : null;

          const depTime = moment(seg.departure.at).format("HH:mm");
          const depDate = moment(seg.departure.at).format("DD MMM, ddd");
          const depCity = formatCity(seg.departure.iataCode);
          const arrTime = moment(seg.arrival.at).format("HH:mm");
          const arrDate = moment(seg.arrival.at).format("DD MMM, ddd");
          const arrCity = formatCity(seg.arrival.iataCode);

          const airlineLogo =
            airlineLogos.find((path) => path.includes(seg?.carrierCode)) ||
            `https://content.airhex.com/content/logos/airlines_${seg?.carrierCode}_85_30_r.png?proportions=keep`;

          return (
            <>
              <div key={`seg-${segIndex}`}>
                {layoverToShow && layoverToShow !== "roundtrip" && (
                  <div className={styles.layoverBlock}>
                    <div className={styles.layoverLeft}>
                      <HistoryToggleOffIcon />
                      <span>Layover</span>
                    </div>
                    <div className={styles.layoverRight}>{layoverToShow}</div>
                  </div>
                )}

                <div className={styles.flightSegment}>
                  <div className={styles.segmentHeader}>
                    <div className={styles.logoCircle}>
                      <img src={airlineLogo} alt={airlineCode} />
                    </div>
                    <div className={styles.airlineName}>
                      {
                        carrierCodes?.find(
                          (item) => item?.value === seg?.carrierCode
                        )?.label
                      }
                    </div>
                    <div className={styles.flightTime}>
                      {formatDuration(seg.duration)} in flight
                    </div>
                  </div>

                  <div className={styles.dashedLine}></div>

                  <div className={styles.segmentRow}>
                    <div className={styles.colLeft}>
                      <div className={styles.iconContainer}>
                        <DepartureFlightIcon />
                      </div>
                      <div className={styles.timeContainer}>
                        <div className={styles.time}>{depTime}</div>
                        <div className={styles.date}>{depDate}</div>
                      </div>
                    </div>

                    <div className={styles.colRight}>
                      <div className={styles.city}>{depCity}</div>
                      <div className={styles.airport}>
                        {seg.departure.iataCode}, Airport
                      </div>
                    </div>
                  </div>

                  <div className={styles.segmentRow}>
                    <div className={styles.colLeft}>
                      <div className={styles.iconContainer}>
                        <ArrivalFlightIcon />
                      </div>
                      <div className={styles.timeContainer}>
                        <div className={styles.time}>{arrTime}</div>
                        <div className={styles.date}>{arrDate}</div>
                      </div>
                    </div>

                    <div className={styles.colRight}>
                      <div className={styles.city}>{arrCity}</div>
                      <div className={styles.airport}>
                        {seg.arrival.iataCode}, Airport
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ItineraryCard;
