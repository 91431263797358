import { useCallback, useEffect, useMemo, useState } from "react";
import {
  flightTypes,
  isurancePercentage,
  paymentTypes,
  weightTypes,
} from "../consts/flights";
import { calculateAge } from "../utils/date";
import moment from "moment";
import { passengerTypes } from "../consts/genders";
import { useDispatch, useSelector } from "react-redux";
import useOrdersAPI from "./api/useOrderAPI";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearOrder,
  setFlights,
  setLead,
  setPassengers,
  setPayments,
} from "../redux/order/orderSlice";
import { setSegmentParams } from "../redux/segment/segmentSlice";
import { leadStatus } from "../consts/lead";
import { useForm, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import useNoteAPI from "./api/useNoteAPI";
import { useQueryClient } from "react-query";
import { clearSelectedHotels, setHotels } from "../redux/hotel/hotelSlice";
import getHotelsTotalSum from "../utils/useGetHotelsPirce";

const normalize = ({
  totalPrice,
  routes,
  flightType,
  id,
  grossPrice,
  nettPrice,
  grossInfantPrice,
  grossChildPrice,
  nettChildPrice,
  nettInfantPrice,
}) => ({
  oneWay: flightType === flightTypes.ONE_WAY,
  id: id || "manual",
  type: "flight-offer",
  source: "TA",
  flightType: flightType,
  childPrice: {
    gross: grossChildPrice,
    nett: nettChildPrice,
    currency: "USD",
  },
  infantPrice: {
    gross: grossInfantPrice,
    nett: nettInfantPrice,
    currency: "USD",
  },
  adultPrice: {
    gross: grossPrice,
    nett: nettPrice,
    currency: "USD",
  },
  price: {
    total: nettPrice || 0,
    currency: "USD",
  },
  grossPrice: {
    total: grossPrice || 0,
    currency: "USD",
  },
  itineraries: [
    ...routes.map((route) => ({
      duration:
        id === "manual"
          ? `PT${route.duration_hours}H${route.duration_minutes}M`
          : route.duration,
      segments: [
        {
          duration:
            id === "manual"
              ? `PT${route.duration_hours}H${route.duration_minutes}M`
              : route.duration,
          departure: {
            iataCode: route.departureIataCode,
            terminal: route.departureTerminal,
            at: route.departure_at,
          },
          arrival: {
            iataCode: route.arrivalIataCode,
            at: route.arrival_at,
          },
          id: "manual",
          carrierCode: route.carrierCode, // HY
          numberOfStops: 0,
        },
      ],
    })),
  ],
});

const normalizePaymentPassenger = (data) => {
  const _calAge = calculateAge(moment(data.date_of_birth).format("MM/DD/YYYY"));
  const calculatedType =
    _calAge > 2
      ? _calAge >= 12
        ? passengerTypes.ADULT
        : passengerTypes.CHILD
      : passengerTypes.INFANT;
  const type = data.type || calculatedType;

  return {
    passport_number: data.passport_number,
    fullname: [data.first_name, data.last_name].join(" "),
    price: data?.price || null,
    ticket_price_nett: data?.ticket_price_nett || null,
    ticket_price_gross: data?.ticket_price_gross || null,
    type,
  };
};

const useOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialPayment, setInitalPayment] = useState({
    total_price: 0,
    flight_price: 0,
    isurance_price: 0,
    with_isurance: true,
    with_hotel: true,
    with_transport: true,
    package_price_gross: null,
    package_price_nett: null,
    status: paymentTypes.PENDING,
    passengers: [],
  });
  const noteControl = useForm();
  const queryClient = useQueryClient();

  const note = useWatch({
    control: noteControl.control,
    name: "note",
  });
  const [debouncedNote] = useDebounce(note, 500);

  const { orderId: existedOrderId } = useParams();

  const { passengers, flights, payments, lead } = useSelector(
    (store) => store.order
  );
  const { selectedHotels } = useSelector((store) => store.hotels);

  const { segmentParams } = useSelector((store) => store.segment);

  const { user } = useSelector((store) => store.auth);
  const { order, createOrder, updateOrder } = useOrdersAPI({
    orderId: existedOrderId,
  });

  const { createNote } = useNoteAPI();

  const isConfirmed = useMemo(
    () => lead?.lead?.status === leadStatus.SOLD,
    [lead]
  );
  const isCanceled = useMemo(
    () => lead?.lead?.status === leadStatus.CANCELLED,
    [lead]
  );

  const [orderId, setOrderId] = useState(existedOrderId);

  useEffect(() => {
    if (!order) return;

    const _flights = [
      ...order?.flights?.map((flight) => JSON.parse(flight.info)),
    ];
    const _passengers = [
      ...order?.customers?.map((customer) => ({
        first_name: customer.first_name,
        second_name: customer.surname,
        last_name: customer.last_name,
        // passport_serie: customer.passport_number.slice(0, 2),
        passport_number: customer.passport_number,
        email: customer.email,
        phone_number: customer.phone_number,
        date_of_birth: customer.date_of_birth,
        gender: customer.gender,
        id: customer?.id,
      })),
    ];
    const _payments = {
      total_price: order.payment.total,
      flight_price: order.payment.price,
      package_price_nett: Number(order?.payment?.package_price_nett),
      package_price_gross: Number(order?.payment?.package_price_gross),
      isurance_price: order.payment.insurance_price,
      with_isurance:
        !!order.payment.insurance_price && order.payment.insurance_price !== 0,
      with_hotel: order.with_hotel,
      with_transport: order.with_transport,
      status: paymentTypes.PENDING,
      passengers: order.passengers_payment_info.map((passenger) => ({
        ...passenger,
        ticket_price_nett: Number(passenger.ticket_price_nett),
        ticket_price_gross: Number(passenger.ticket_price_gross),
        fullname: passenger.fullname,
        passenger: passenger.type,
        weight: passenger.weight,
      })),
      passengers_payment_info: order.passengers_payment_info.map(
        (passenger) => ({
          ticket_price_nett: Number(passenger.ticket_price_nett),
          ticket_price_gross: Number(passenger.ticket_price_gross),
          fullname: passenger.fullname,
          passenger: passenger.type,
          weight: passenger.weight,
        })
      ),
      // passengers: order?.customer_payments && [
      //   ...JSON.parse(order?.customer_payments)?.map((item) => ({
      //     ...item,
      //     price: Number(item?.price),
      //     type: item?.passenger,
      //   })),
      // ],
    };

    const _hotels = {
      hotels: order.orderHotels.map((item) => ({
        ...item,
        ...item.hotel,
        unique_id: Math.random().toString(),
        value: item?.hotel_id,
        rooms: item?.hotelRooms.map((el) => ({
          ...el,
          name: el?.roomType?.name,
          room_option_ids: el?.roomOptions.map((room) => room.id),
        })),
        country: item?.hotel?.region?.country?.name,
        region: item?.hotel?.region?.name,
      })),
    };

    dispatch(setFlights(_flights));
    dispatch(setPassengers(_passengers || []));
    dispatch(setPayments(_payments));
    dispatch(setHotels(_hotels?.hotels || []));
    setInitalPayment(_payments);
    dispatch(
      setLead({
        lead: { ...order.lead, user_id: order?.user?.id },
        segments: order.segments,
        external_order_id: order.external_order_id,
        tg_chat_id: order?.tg_chat_id,
      })
    );

    return () => {
      if (!!order) {
        dispatch(clearOrder());
        dispatch(clearSelectedHotels());
      }
    };
  }, [dispatch, order]);

  useEffect(() => {
    if (!passengers || passengers?.length === 0) return;

    const _paymentPassengers = passengers.map((passenger) => {
      const foundPassenger = initialPayment?.passengers.find(
        (psg) =>
          psg.fullname === [passenger.first_name, passenger.last_name].join(" ")
      );

      return {
        ...normalizePaymentPassenger(passenger),
        price: foundPassenger?.price || null,
        ticket_price_nett: foundPassenger?.ticket_price_nett || null,
        ticket_price_gross: foundPassenger?.ticket_price_gross || null,
        weight: {
          ...(foundPassenger?.weight || { value: null, unit: weightTypes.KG }),
        },
      };
    });

    dispatch(
      setPayments({ ...initialPayment, passengers: [..._paymentPassengers] })
    );
  }, [passengers, initialPayment]);

  const changePaymentStatus = (value) =>
    dispatch(setPayments({ ...payments, status: value }));

  const switchPaymentVals = (key, value) =>
    dispatch(setPayments({ ...payments, [key]: value }));

  const addFlight = (data) => {
    const normalizedData = normalize(data);
    dispatch(setFlights([...flights, normalizedData]));
  };

  const changeFlight = (data, index) => {
    const oldFlights = [...flights];

    oldFlights[index] = normalize(data);
    dispatch(setFlights(oldFlights));
  };

  const deleteFlight = (index) =>
    dispatch(setFlights(flights.filter((_, idx) => idx !== index)));

  const changePassengerPrice = (index, fieldName, value) => {
    const _payments = { ...payments };
    const _passengers = [..._payments.passengers];
    const _passenger = { ..._passengers[index] };

    const fieldHandlers = {
      ticket_price_gross: (pass, val) => {
        pass.ticket_price_gross = val;
      },
      ticket_price_nett: (pass, val) => {
        pass.ticket_price_nett = val;
      },
    };

    if (fieldHandlers[fieldName]) {
      fieldHandlers[fieldName](_passenger, value);
    }

    _passengers[index] = _passenger;

    const flight_price = _payments.passengers.reduce((prev, curr) => {
      return prev + Number(curr.ticket_price_gross);
    }, 0);

    const isurance_price = (flight_price * isurancePercentage) / 100;

    dispatch(
      setPayments({
        ..._payments,
        passengers: _passengers,
        flight_price,
        isurance_price,
        total_price: flight_price,
      })
    );
  };

  const changeWeightValue = (index, value) => {
    const _payments = { ...payments };
    const _passengers = [..._payments.passengers];
    let _passenger = { ..._passengers[index] };

    _passenger.weight = { ..._passenger.weight, value };
    _passengers[index] = { ..._passenger };

    dispatch(
      setPayments({
        ..._payments,
        passengers: _passengers,
      })
    );
  };

  useEffect(() => {
    const _payments = { ...payments };

    const flight_price = _payments.passengers?.reduce((prev, curr) => {
      return prev + Number(curr.ticket_price_gross);
    }, 0);

    dispatch(
      setPayments({
        ..._payments,
        flight_price,
        total_price: flight_price,
      })
    );
  }, [payments.with_isurance, payments.flight_price, payments.passengers]);

  const changePassengerType = (index, value) => {
    const _payments = { ...payments };
    const _passengers = [..._payments.passengers];
    const _passenger = { ..._passengers[index] };

    _passenger.type = value;
    _passengers[index] = _passenger;

    dispatch(setPayments({ ..._payments, passengers: _passengers }));
  };

  const changeWeightUnit = (index, value) => {
    const _payments = { ...payments };
    const _passengers = [..._payments.passengers];
    const _passenger = { ..._passengers[index] };

    _passenger.weight = {
      ..._passenger.weight,
      unit: value,
      value: value === weightTypes.NO ? "" : _passenger.weight.value,
    };
    _passengers[index] = _passenger;

    dispatch(setPayments({ ..._payments, passengers: _passengers }));
  };

  const addSegmentParams = (params) => {
    dispatch(setSegmentParams(params));
  };

  const addLeadInformations = (params) => {
    dispatch(setLead(params));
  };

  const handleCreateNote = (orderId) => {
    createNote.mutate({ content: debouncedNote, order_id: orderId });
  };

  const hotelSum = getHotelsTotalSum(selectedHotels, "grous_price");

  const handleCreateOrder = useCallback(
    (leadInfo) => {
      const payload = {
        is_confirmed: isConfirmed || false,
        with_hotel: payments.with_hotel,
        with_transport: payments.with_transport,
        has_insurance: true,
        price: payments.total_price,
        user_id: leadInfo?.lead?.user_id || user.id,
        flights: flights?.map((flight) => {
          const foundFlight = order?.flights?.find(
            (fl) => fl.info === JSON.stringify(flight)
          );
          if (foundFlight) {
            return {
              id: foundFlight.id,
              type: flight.flightType,
              price: flight.price.total,
              info: flight,
            };
          }
          return {
            type: flight.flightType,
            price: flight.price.total,
            info: flight,
          };
        }),
        payment: {
          id: order?.payment?.id,
          price: payments.flight_price,
          insurance_price: 0,
          total:
            Number(payments.total_price) +
            Number(hotelSum) +
            Number(payments.package_price_gross),
          package_price_nett: Number(payments.package_price_nett),
          package_price_gross: Number(payments.package_price_gross),
        },
        customer_payments: payments.passengers.map((passenger) => ({
          price: Number(passenger.ticket_price_gross),
          fullname: passenger.fullname,
          passenger: passenger.type,
          weight: passenger.weight,
          ticket_price_nett: Number(passenger.ticket_price_nett),
          ticket_price_gross: Number(passenger.ticket_price_gross),
        })),
        passengers_payment_info: payments.passengers.map((passenger) => ({
          ticket_price_nett: Number(passenger.ticket_price_nett),
          ticket_price_gross: Number(passenger.ticket_price_gross),
          fullname: passenger.fullname,
          passenger: passenger.type,
          weight: passenger.weight,
        })),
        customers: passengers.map((passenger) => ({
          id:
            passenger.id ||
            order?.customers.find(
              (psg) => psg.passport_number === passenger.passport_number
            )?.id,
          first_name: passenger.first_name,
          surname: passenger.second_name,
          last_name: passenger.last_name,
          passport_number: passenger.passport_number.toUpperCase(),
          email: passenger.email,
          phone_number: passenger.phone_number,
          date_of_birth: passenger.date_of_birth,
          gender: passenger.gender?.toLowerCase(),
        })),
        hotels: selectedHotels.map((item) => ({
          rooms: item.rooms,
          hotel_id: item.value,
          check_in: item.check_in,
          check_out: item.check_out,
          adult: item.adult,
          children: item?.children,
          infant: item?.infant,
          policies: item?.policies,
          max_age: parseFloat(item?.max_age),
          net_price: parseFloat(item?.net_price),
          grous_price: parseFloat(item?.grous_price),
        })),
        lead: leadInfo
          ? {
              ...leadInfo?.lead,
              status: leadInfo?.lead.status || "active",
            }
          : {
              ...lead.lead,
              status: lead?.lead.status || "active",
            },
        segments: leadInfo?.segments || lead.segments,
        tg_chat_id: leadInfo?.tg_chat_id || lead?.tg_chat_id || "",
      };

      if (existedOrderId)
        toast.promise(
          updateOrder.mutateAsync({
            ...payload,
            id: existedOrderId,
            external_order_id: lead.external_order_id,
          }),
          {
            loading: "Saving...",
            success: (res) => {
              setOrderId(res.order?.id);
              navigate("../");
              dispatch(clearOrder());
              dispatch(clearSelectedHotels());
              return "Application successfully modified";
            },
            error: (err) => {
              return err?.data?.message;
            },
          }
        );
      else
        toast.promise(createOrder.mutateAsync(payload), {
          loading: "Creating...",
          success: (res) => {
            setOrderId(res.order?.id);
            handleCreateNote(res.order?.id);
            navigate("../");
            dispatch(clearOrder());
            dispatch(clearSelectedHotels());
            return "Application was created successful";
          },
          error: (err) => {
            return err?.data?.message;
          },
        });
    },
    [flights, passengers, payments, user, lead, selectedHotels]
  );

  const handleCreateChatOrder = useCallback((leadInfo) => {
    const payload = {
      is_confirmed: isConfirmed || false,
      with_hotel: payments.with_hotel,
      with_transport: payments.with_transport,
      has_insurance: true,
      price: payments.total_price,
      user_id: user.id,
      flights: flights?.map((flight) => {
        const foundFlight = order?.flights?.find(
          (fl) => fl.info === JSON.stringify(flight)
        );
        if (foundFlight) {
          return {
            id: foundFlight.id,
            type: flight.flightType,
            price: flight.price.total,
            info: flight,
          };
        }
        return {
          type: flight.flightType,
          price: flight.price.total,
          info: flight,
        };
      }),
      payment: {
        id: order?.payment?.id,
        price: payments.flight_price,
        insurance_price: 0,
        total: payments.total_price,
        package_price_nett: Number(payments.package_price_nett),
        package_price_gross: Number(payments.package_price_gross),
      },
      customer_payments: payments.passengers.map((passenger) => ({
        price: Number(passenger.price),
        fullname: passenger.fullname,
        passenger: passenger.type,
        weight: passenger.weight,
        ticket_price_nett: Number(passenger.ticket_price_nett),
        ticket_price_gross: Number(passenger.ticket_price_gross),
      })),
      passengers_payment_info: payments.passengers.map((passenger) => ({
        ticket_price_nett: Number(passenger.ticket_price_nett),
        ticket_price_gross: Number(passenger.ticket_price_gross),
        fullname: passenger.fullname,
        passenger: passenger.type,
        weight: passenger.weight,
      })),
      customers: passengers.map((passenger) => ({
        id:
          passenger.id ||
          order?.customers.find(
            (psg) => psg.passport_number === passenger.passport_number
          )?.id,
        first_name: passenger.first_name,
        surname: passenger.second_name,
        last_name: passenger.last_name,
        passport_number: passenger.passport_number.toUpperCase(),
        email: passenger.email,
        phone_number: passenger.phone_number,
        date_of_birth: passenger.date_of_birth,
        gender: passenger.gender?.toLowerCase(),
      })),
      lead: leadInfo
        ? {
            ...leadInfo?.lead,
            status: leadInfo?.lead.status || "active",
          }
        : {
            ...lead.lead,
            status: lead?.lead.status || "active",
          },
      segments: leadInfo?.segments || lead.segments,
      tg_chat_id: leadInfo?.tg_chat_id || "",
    };

    toast.promise(createOrder.mutateAsync(payload), {
      loading: "Creating...",
      success: () => {
        dispatch(clearOrder());
        dispatch(clearSelectedHotels());
        queryClient.refetchQueries(["GET_ORDERS"]);
        return "Application was created successful";
      },
      error: (err) => {
        return err?.data?.message;
      },
    });
  }, []);

  const changePackagePrice = (fieldName, value) => {
    const _payments = { ...payments };

    const fieldHandlers = {
      package_price_nett: (obj, val) => {
        obj.package_price_nett = val;
      },
      package_price_gross: (obj, val) => {
        obj.package_price_gross = val;
      },
    };

    if (fieldHandlers[fieldName]) {
      fieldHandlers[fieldName](_payments, value);
    }

    dispatch(setPayments(_payments));
  };

  return {
    flights,
    orderId,
    passengers,
    payments,
    hotels: selectedHotels,
    lead,
    isConfirmed,
    segmentParams,
    isCanceled,
    addFlight,
    changeFlight,
    deleteFlight,
    setPassengers: (psngrs) => dispatch(setPassengers(psngrs)),
    changePassengerPrice,
    changePassengerType,
    changePaymentStatus,
    changeWeightUnit,
    changeWeightValue,
    switchPaymentVals,
    handleCreateOrder,
    addSegmentParams,
    addLeadInformations,
    handleCreateChatOrder,
    changePackagePrice,
    noteControl,
  };
};

export default useOrder;
