import { orderSections } from "../pages/Orders/Create";

export const leadTypes = {
  NEW: "NEW",
  RETURN: "RETURN",
  REFFERAL: "REFFERAL",
};
export const leadStatus = {
  ACTIVE: "active",
  SOLD: "sold",
  EXCHANGED: "exchanged",
  CANCELLED: "cancelled",
  BQ: "bq",
  LOCK: "lock",
};
export const submitType = {
  SAVE_QUITE: "save_quit",
  SAVE_CONTINUE: "save_continue",
};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    minWidth: "auto",
    background: "#fff",
  }),
};

export const customHotelStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow:
      "0px 1px 2px 0px #1018280D, 0px -2px 0px 0px #1018280D inset, 0px 0px 0px 1px #1018280D inset",
    borderRadius: "12px",
    padding: "8px",
  }),
};

export const leadTypesArray = [
  {
    label: "New",
    value: leadTypes.NEW,
  },
  {
    label: "Return",
    value: leadTypes.RETURN,
  },
  {
    label: "Refferal",
    value: leadTypes.REFFERAL,
  },
];

export const statusesArray = [
  {
    label: "Active",
    value: leadStatus.ACTIVE,
  },
  {
    label: "Sold",
    value: leadStatus.SOLD,
  },
  {
    label: "Cancelled",
    value: leadStatus.CANCELLED,
  },
  {
    label: "BQ",
    value: leadStatus.BQ,
  },
  {
    label: "Exchanged",
    value: leadStatus.EXCHANGED,
  },
  // {
  //   label: "Lock",
  //   value: leadStatus.LOCK,
  // },
];

export const statusStyles = {
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
    background: data.background,
  }),
  menu: (styles) => ({
    ...styles,
    width: "100%",
    padding: "6px",
    border: "none",
    borderRadius: "6px",
    boxShadow: "none",
  }),
  option: (styles, { data }) => {
    return {
      ...styles,
      background: data.background,
      color: data.color,
      maxWidth: "auto",
      marginBottom: "4px",
      borderRadius: "6px",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: data.background,
        color: data.color,
      },
    };
  },
};

export const leadStatusArray = [
  {
    label: "Active",
    value: leadStatus.ACTIVE,
    color: "#004EEB",
    background: "#EFF4FF",
  },
  {
    label: "Sold",
    value: leadStatus.SOLD,
    color: "#087443",
    background: "#EDFCF2",
  },
  {
    label: "Exchanged",
    value: leadStatus.EXCHANGED,
    color: "#CA8504",
    background: "#FEFBE8",
  },
  {
    label: "Cancelled",
    value: leadStatus.CANCELLED,
    color: "#B42318",
    background: "#FEF3F2",
  },
];

export const LeadStatusTextColor = (name) => {
  switch (name) {
    case leadStatus.ACTIVE:
      return "#004EEB";
    case leadStatus.SOLD:
      return "#087443";
    case leadStatus.EXCHANGED:
      return "#CA8504";
    case leadStatus.CANCELLED:
      return "#FF0000";

    default:
      return "-";
  }
};

export const LeadStatusBackgroundColor = (name) => {
  switch (name) {
    case leadStatus.ACTIVE:
      return "#EFF4FF";
    case leadStatus.SOLD:
      return "#EDFCF2";
    case leadStatus.EXCHANGED:
      return "#FEFBE8";
    case leadStatus.CANCELLED:
      return "#FEF3F2";

    default:
      return "-";
  }
};

export const LeadStatusText = (name) => {
  switch (name) {
    case leadStatus.ACTIVE:
      return "ACTIVE";
    case leadStatus.SOLD:
      return "SOLD";
    case leadStatus.EXCHANGED:
      return "EXCHANGED";
    case leadStatus.CANCELLED:
      return "CANCELLED";

    default:
      return "-";
  }
};

export const checkTab = (pathname, lead, passengers, flights, navigate, id) => {
  switch (true) {
    case Boolean(
      pathname?.includes(orderSections.LEAD) && lead?.segments?.length
    ):
      return navigate(id);
    case Boolean(pathname?.includes(orderSections.PASSENGERS)):
      return navigate(id);
    case pathname?.includes(orderSections.PAYMENTS):
      return navigate(id);
    case pathname?.includes(orderSections.HOTELS):
      return navigate(id);
    default:
      return;
  }
};
