import { Box, Button } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { orderSections } from "../..";

import Content from "../../../../../components/Content";
import PassengerItem from "./PassengerItem";
import usePassenger from "../../../../../hooks/usePassenger";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import styles from "./PassengerItem/style.module.scss";
import { useSelector } from "react-redux";
import useOrderAPI from "../../../../../hooks/api/useOrderAPI";

const Passengers = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { passengers, setPassengers, isConfirmed, isCanceled } =
    useOutletContext();
  const {
    initPassengers,
    addPassenger,
    removePassenger,
    changePassengerDetail,
  } = usePassenger({ passengers });
  const { createExchangeOrder } = useOrderAPI({});
  const { exchangePassengers } = useSelector((store) => store.order);

  const onContinue = useCallback(() => {
    if (initPassengers?.length === 0) return toast.error("Add a passenger!");

    if (!isConfirmed) setPassengers(initPassengers);

    navigate(`../${orderSections.HOTELS}`);
  }, [isConfirmed, initPassengers]);

  const handleBack = () => {
    navigate(`../${orderSections.LEAD}`);
  };

  const handleCreateExchangeOrder = () => {
    const payload = {
      customer_ids: exchangePassengers,
      order_id: orderId,
    };
    toast.promise(createExchangeOrder.mutateAsync(payload), {
      loading: "Creating a new order...",
      success: (res) => {
        navigate(`/orders/${res?.id}/lead`);
        return "Order successfully created";
      },
      error: "Error while modifying the request!",
    });
  };

  return (
    <Box width="100%" height="calc(100vh - 144px)" position="relative">
      <Content
        title="Passengers"
        extra={
          <Box className={styles.passenger_buttons}>
            {isCanceled || isConfirmed ? (
              <Button
                variant="contained"
                onClick={handleCreateExchangeOrder}
                className={styles.exchange_button}
              >
                Exchange
              </Button>
            ) : null}
            {!isConfirmed && (
              <Button
                variant="contained"
                className={styles.add_button}
                onClick={addPassenger}
              >
                <AddOutlinedIcon
                  style={{
                    marginRight: "5px",
                  }}
                />
                New Passenger
              </Button>
            )}
          </Box>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          mt="16px"
          rowGap={"16px"}
        >
          {initPassengers.map((passenger, p) => (
            <PassengerItem
              data={passenger}
              key={p}
              isConfirmed={isConfirmed}
              isCanceled={isCanceled}
              removePassenger={() => removePassenger(p)}
              isRemovable={initPassengers?.length > 1}
              changePassengerDetail={(key, value) =>
                changePassengerDetail(p, key, value)
              }
            />
          ))}
        </Box>
      </Content>
      <Box
        position="absolute"
        left="-17px"
        bottom="-17px"
        width="calc(100% + 34px)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="white"
        height={56}
        padding="8px"
        style={{
          "box-shadow": " 0px 1px 0px 0px rgba(229, 233, 235, 1) inset",
        }}
      >
        {isConfirmed && (
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={onContinue}
          style={{ marginLeft: 12 }}
          variant="contained"
        >
          {isConfirmed ? "Next" : "Save & continue"}
          <ArrowForwardIosOutlinedIcon
            style={{
              fontSize: "12px",
              marginLeft: 12,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Passengers;
