import React, { useMemo, useState, useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import classNames from "classnames";
import LightGallery from "lightgallery/react";
import lgVideo from "lightgallery/plugins/video";
import lgZoom from "lightgallery/plugins/zoom";
import lgRotate from "lightgallery/plugins/rotate";
import ImagePiP from "./ImagePip";
import useChats from "../../../../../../hooks/api/useChatsAPI";
import { useParams } from "react-router-dom";
import { TELEGRAM_CDN_URL } from "../../../../../../utils/telegramApi";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-rotate.css";
import "lightgallery/scss/lg-zoom.scss";

const ImageMessage = ({ message, isMergedMedia, isOther }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPip, setIsPip] = useState(false);

  const galleryRef = useRef(null);

  const { id, hashId } = useParams();

  const { media } = useChats({
    hash: hashId,
    id: id,
  });

  const gallery = useMemo(() => {
    const allMessages = media?.payload?.messages?.Messages || [];
    return allMessages
      .reverse()
      .filter((item) => item?.Media?.links?.length)
      .map((item) => {
        const link = item?.Media?.links[0] || "/default_image.png";
        const fullSrc = `${TELEGRAM_CDN_URL}${link}`;
        const isVideo = fullSrc.endsWith(".mp4") || fullSrc.endsWith(".webm");

        if (isVideo) {
          return {
            video: {
              thumb: "/images/default.jpg",
              subHtml: "<p>test</p>",
              source: [
                {
                  src: fullSrc,
                  type: "video/mp4",
                },
              ],
              attributes: {
                preload: "false",
                controls: true,
              },
            },
          };
        } else {
          return {
            src: fullSrc,
            thumb: fullSrc,
            width: 3000,
          };
        }
      });
  }, [media]);

  const onInit = useCallback((detail) => {
    if (!detail?.instance) return;
    galleryRef.current = detail.instance;
  }, []);

  const handleOpenGallery = () => {
    const currentLink = message?.Media?.links?.[0];
    if (!currentLink) return;

    const fullSrc = `${TELEGRAM_CDN_URL}${currentLink}`;
    const indexInGallery = gallery.findIndex((item) => {
      if (item.src === fullSrc) {
        return true;
      }
      const videoSrc = item.video?.source?.[0]?.src;
      if (videoSrc === fullSrc) {
        return true;
      }
      return false;
    });

    if (indexInGallery >= 0 && galleryRef.current) {
      galleryRef.current.openGallery(indexInGallery);
      setActiveIndex(indexInGallery);
    }
  };

  return (
    <>
      {isPip && gallery[activeIndex] && gallery[activeIndex].src && (
        <ImagePiP
          setIsPip={setIsPip}
          isPip={isPip}
          src={gallery[activeIndex]?.src}
        />
      )}

      <LazyLoadImage
        alt="default"
        className={classNames(styles.image, {
          [styles.merged]: isMergedMedia,
          [styles.other]: isOther,
        })}
        effect="blur"
        onClick={handleOpenGallery}
        src={
          message?.Media?.links?.[0]
            ? `${TELEGRAM_CDN_URL}${message?.Media?.links[0]}`
            : "/default_image.png"
        }
      />

      <LightGallery
        dynamic={true}
        onInit={onInit}
        dynamicEl={gallery}
        speed={500}
        subHtmlSelectorRelative
        key={gallery[activeIndex]}
        showZoomInOutIcons
        actualSize={false}
        plugins={[lgZoom, lgVideo, lgRotate]}
        onBeforeClose={() => setIsPip(true)}
        onAfterSlide={({ index }) => {
          setActiveIndex(index);
        }}
      ></LightGallery>
    </>
  );
};

export default ImageMessage;
