import React, { useMemo } from "react";
import TSelect from "../../../components/Select";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import RevenueCard from "../../../components/RevenueCard";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { isUserAdmin, isUserSupervisor } from "../../../utils";
import { getAgentsByName } from "../../../utils/getAgents";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../../consts/dates";
import styles from "../style.module.scss";
import WavyLineChart from "../Charts/WavyLineChart";
import moment from "moment";

export default function OverallStats({
  user,
  users,
  onChangeAgentsFilter,
  filters,
  chartData,
  onCleanDate,
  onClearFilter,
  result,
  onChangeDateFilter,
  onChangeType,
  type,
  dashboardData,
}) {
  const defaultValue = [
    new Date(moment().startOf("month").add(1, "days").format("YYYY-MM-DD")),
    new Date(moment().endOf("month").add(1, "days").format("YYYY-MM-DD")),
  ];
  const conversion = useMemo(() => {
    return (dashboardData?.sold_count / dashboardData?.lead_count) * 100;
  }, [dashboardData]);

  const renderedComponent = useMemo(() => {
    return (
      <Box className={styles.dashboard}>
        <Box width={"100%"} maxWidth={"300px"}>
          <Box
            display="flex"
            gap="8px"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center" gap="10px" width={"100%"}>
              <DateRangePicker
                key={filters?.start_date + filters?.end_date}
                value={
                  (filters.start_date || filters.end_date) && [
                    new Date(filters.start_date),
                    new Date(filters.end_date),
                  ]
                }
                defaultValue={defaultValue}
                ranges={predefinedRanges}
                placeholder="Select date"
                onClean={onCleanDate}
                // shouldDisableDate={(dt) => {
                //   return new Date() < dt;
                // }}
                onChange={onChangeDateFilter}
                placement="auto"
                preventOverflow
                className={styles.date_picker}
              />
            </Box>
          </Box>

          <Box display="flex" gap="16px" mt="16px" flexDirection={"column"}>
            <RevenueCard
              title="Agent tips"
              value={result?.agentTips}
              prefix="$"
            />
            <RevenueCard title="Orders count" value={result?.orderCount} />
            {(isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
              <RevenueCard title="Agents amount" value={result?.agentCount} />
            )}
            <RevenueCard
              title="Profit"
              value={
                result?.ticketMargin +
                result?.packageMargin +
                result?.hotelMargin
              }
              prefix="$"
            />

            {dashboardData?.sold_count || dashboardData?.lead_count ? (
              <RevenueCard
                title="Conversion"
                value={conversion}
                suffix="%"
                children={
                  <span className={styles.conversion_element}>
                    {` • ${dashboardData?.sold_count}/${dashboardData?.lead_count}`}
                  </span>
                }
              />
            ) : null}
          </Box>
        </Box>
        <Box width={"100%"} className={styles.chart}>
          <Box className={styles.filters}>
            <Typography>Sales report</Typography>
            <div className={styles.agent_select}>
              {(isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
                <TSelect
                  isMulti={true}
                  components={{
                    DropdownIndicator: PersonRoundedIcon,
                    IndicatorSeparator: null,
                  }}
                  value={users?.users?.rows?.filter((user) =>
                    filters?.agents?.includes(user.id)
                  )}
                  loadOptions={getAgentsByName}
                  defaultOptions={users?.users?.rows}
                  getOptionLabel={(opt) => opt.first_name}
                  onChange={onChangeAgentsFilter}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select agents"
                />
              )}
            </div>
          </Box>
          <div className={styles.chart_card} id="dashboard_chart">
            <Tabs
              value={type}
              onChange={onChangeType}
              sx={{ "& .MuiTabs-indicator": { display: "none" } }}
            >
              <Tab value="day" label="Daily" />
              <Tab value="month" label="Monthly" />
            </Tabs>
            <WavyLineChart data={chartData} />
          </div>
        </Box>
      </Box>
    );
  }, [result, dashboardData]);

  return renderedComponent;
}
