import styles from "./style.module.scss";
import { flightTypesArray } from "../../../../../../../consts/flights";
import { FSelect } from "../../../../../../../components/Select";

const SearchParams = ({ flightType, changeFlightType }) => {
  const onChangeFlightType = (e) => {
    changeFlightType(e?.key);
  };

  return (
    <div className={styles.params}>
      <div className={styles.box}>
        <FSelect
          options={flightTypesArray}
          placeholder="Flight type"
          value={flightTypesArray.find((item) => item?.key === flightType)}
          components={{ IndicatorSeparator: null }}
          onChange={onChangeFlightType}
          getOptionLabel={(opt) => opt.value}
          getOptionValue={(opt) => opt.key}
          styles={{
            control: () => ({
              minWidth: "100px",
            }),
          }}
        />
      </div>
    </div>
  );
};

export default SearchParams;
