import { Box, Button, CircularProgress, Typography } from "@mui/material";

import styles from "./style.module.scss";
import LeadFlightTicket from "../../../../../components/LeadFlightTIcket";
import { IOSSwitch } from "../../../../../themes/overrides/IosSwitch";
import FlightBasket from "./SegmentForm/FlightBasket";
import { useState } from "react";
import TicketMockUp from "./TicketMockUp";

const Flights = ({
  currentTicket,
  selectTicket,
  tickets,
  isRoundTrip,
  flights,
  flight,
  onSave,
  loader,
  captureRef,
  setSelectedFlight,
  setSelectedIndex,
  setEditMode,
  editMode,
  setIsModalOpen,
}) => {
  const [clientMode, setClientMode] = useState(false);

  const onEdit = (data, index) => {
    if (data?.id === "manual") {
      setIsModalOpen(true);
      setSelectedFlight(data);
      setSelectedIndex(index);
    } else {
      selectTicket(data);
      setSelectedFlight(data);
      setSelectedIndex(index);
      setEditMode(true);
    }
  };

  return (
    <div
      className={`${styles.tickets} ${
        flights?.length && styles.scroll_tickets
      }`}
    >
      {flights?.length ? (
        <div className={styles.selected_flights}>
          <div className={styles.head}>
            <Typography variant="h5">Selected flights</Typography>
          </div>
          <FlightBasket
            onEdit={onEdit}
            editMode={editMode}
            onClick={selectTicket}
            currentTicket={currentTicket}
          />
        </div>
      ) : null}
      <div className={styles.head}>
        <Typography variant="h5">Options</Typography>
        <div className={styles.client_mode}>
          <p> Client mode</p>{" "}
          <IOSSwitch
            sx={{ m: 1 }}
            onChange={(e) => setClientMode(e.target.checked)}
          />
          {flight?.routes[0]?.departureIataCode && !currentTicket?.isManual ? (
            <Button
              variant={
                !flight?.routes[0]?.departureIataCode
                  ? "containedSecondary"
                  : "containedSuccess"
              }
              disabled={!flight?.routes[0]?.departureIataCode}
              onClick={onSave}
              className={styles.save_button}
            >
              {editMode ? "Edit" : "Save"}
            </Button>
          ) : null}
        </div>
      </div>
      <div className={styles.list}>
        {currentTicket && editMode ? (
          <LeadFlightTicket
            data={currentTicket}
            onClick={selectTicket}
            isRoundTrip={isRoundTrip}
            clientMode={clientMode}
            isActive={true}
            editMode
          />
        ) : null}
        {!loader && !editMode
          ? tickets?.map((ticket) => (
              <LeadFlightTicket
                key={ticket.id}
                onClick={selectTicket}
                data={ticket}
                isRoundTrip={isRoundTrip}
                isActive={currentTicket?.id === ticket.id}
                clientMode={clientMode}
                currentTicket={currentTicket}
              />
            ))
          : null}
        {loader && (
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        )}
      </div>

      {currentTicket && (
        <TicketMockUp currentTicket={currentTicket} captureRef={captureRef} />
      )}
    </div>
  );
};

export default Flights;
