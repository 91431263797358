import React, { useRef, useCallback, useState } from "react";
import { CloseIcon } from "../../../../../../assets/icons";
import { Box } from "@mui/material";
import { ResizableBox } from "react-resizable";
import styles from "./styles.module.scss";
import "react-resizable/css/styles.css";

const ImagePiP = ({ isPip, src, setIsPip }) => {
  const imageRef = useRef(null);
  const offsetRef = useRef({ x: 0, y: 0 });
  const positionRef = useRef({ x: 0, y: 0 });
  const draggingRef = useRef(false);
  const [size, setSize] = useState({ width: 300, height: "" });

  const handleMouseDown = useCallback((e) => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      draggingRef.current = true;
      offsetRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    draggingRef.current = false;
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (draggingRef.current && imageRef.current) {
      requestAnimationFrame(() => {
        positionRef.current = {
          x: e.clientX - offsetRef.current.x,
          y: e.clientY - offsetRef.current.y,
        };
        imageRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
      });
    }
  }, []);

  const onResize = useCallback((e, { size }) => {
    setSize(size);
  }, []);

  return (
    <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      {isPip && (
        <Box
          onMouseDown={handleMouseDown}
          onMouseEnter={() => (draggingRef.current = false)}
          ref={imageRef}
          className={styles.pip}
          style={{
            cursor: draggingRef.current ? "grabbing" : "grab",
            transform: `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`,
            width: size.width,
            height: size.height,
          }}
        >
          <ResizableBox
            className={styles.resize}
            width={size.width}
            height={size.height}
            onResize={onResize}
          >
            <Box onClick={() => setIsPip(false)} className={styles.close}>
              <CloseIcon />
            </Box>
            <img
              src={src}
              alt="PiP"
              style={{ width: "100%", height: "100%", pointerEvents: "none" }}
            />
          </ResizableBox>
        </Box>
      )}
    </div>
  );
};

export default ImagePiP;
