import moment from "moment";
import TSelect from "../../../../../../../components/Select";
import styles from "./style.module.scss";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

import { flightTypes, iataCodes } from "../../../../../../../consts/flights";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getDifferenceOfTwoDates,
  getYesterdayDate,
} from "../../../../../../../utils/date";
import { promiseCity } from "../../../../../../../utils/getCities";

const DefaultForm = ({ changeRouteField, data, keyIndex, prevIndex }) => {
  const [fromSelectedDate, setFromSelectedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);

  const onChangeSelect = (e, index, key) => {
    changeRouteField(index, key, e?.key || e?.value);
  };

  const onChangeInput = (e, index, key) => {
    changeRouteField(index, key, e.target.value);
  };

  const onChangeDate = (e, index, key) => {
    const newDate = moment(`${e.year}/${e.month}/${e.day}`);

    changeRouteField(index, key, newDate);
  };

  const onChangeTime = (e, index, key) => {
    const hour = Number(e.target.value.split(":")[0]);
    const minute = Number(e.target.value.split(":")[1]);

    changeRouteField(
      index,
      key,
      moment(data[key])
        .set("hour", hour)
        .set("minute", minute)
        .set("second", 0)
        .set("millisecond", 0)
        .format("YYYY-MM-DDTHH:mm:ss")
    );
  };

  const onChangeDuration = (e, index, key) => {
    changeRouteField(index, key, e.target.value);
  };

  useEffect(() => {
    if (!data.departure_at) return;

    setFromSelectedDate({
      day: moment(data.departure_at).get("date"),
      month: moment(data.departure_at).get("month") + 1,
      year: moment(data.departure_at).get("year"),
    });
  }, [data.departure_at]);

  useEffect(() => {
    if (!data.arrival_at) return;

    setToSelectedDate({
      day: moment(data.arrival_at).get("date"),
      month: moment(data.arrival_at).get("month") + 1,
      year: moment(data.arrival_at).get("year"),
    });
  }, [data.arrival_at]);

  useEffect(() => {
    if (!data.arrival_at || !data.departure_at) return;

    changeRouteField(
      0,
      "duration",
      getDifferenceOfTwoDates(data.departure_at, data.arrival_at)
    );
  }, [data.arrival_at, data.departure_at]);

  return (
    <div
      className={styles.one_way}
      style={{
        position: "relative",
        zIndex: prevIndex + 1,
      }}
    >
      <div className={styles.departure}>
        <div className={styles.field}>
          <p className={styles.label}>From</p>
          <TSelect
            placeholder="From"
            value={{
              value: data.departureIataCode,
              key: data.departureIataCode,
            }}
            loadOptions={promiseCity}
            components={{ IndicatorSeparator: null }}
            onChange={(e) => onChangeSelect(e, keyIndex, "departureIataCode")}
            getOptionLabel={(opt) => opt.key || opt.label || opt.value}
            getOptionValue={(opt) => opt.value}
            styles={{
              control: () => ({
                backgroundColor: "#fff",
                minWidth: "280px",
                border: "1px solid rgba(229, 233, 235, 1)",
              }),
            }}
          />
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Departure Date</p>

          <td className={styles.date_picker}>
            <DatePicker
              value={fromSelectedDate}
              onChange={(e) => onChangeDate(e, keyIndex, "departure_at")}
              inputPlaceholder="Departure date"
              minimumDate={getYesterdayDate()}
              colorPrimary="var(--primary-color)"
              wrapperClassName={styles.datepicker}
              shouldHighlightWeekends
            />
          </td>
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Departure Time</p>

          <input
            type="time"
            className={styles.datetime}
            value={moment(data.departure_at).format("HH:mm")}
            onChange={(e) => onChangeTime(e, keyIndex, "departure_at")}
          />
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Flight Duration</p>

          <Box display="flex" gap="5px">
            <input
              type="number"
              max={99}
              min={0}
              className={styles.datetime}
              placeholder="Hours"
              value={data.duration_hours}
              onChange={(e) => onChangeDuration(e, keyIndex, "duration_hours")}
            />
            <input
              type="number"
              max={59}
              min={0}
              className={styles.datetime}
              placeholder="Minutes"
              value={data.duration_minutes}
              onChange={(e) =>
                onChangeDuration(e, keyIndex, "duration_minutes")
              }
            />
          </Box>
        </div>
      </div>
      <div className={styles.arrival}>
        <div className={styles.field}>
          <p className={styles.label}>To</p>
          <TSelect
            placeholder="To"
            // value={iataCodes?.find(
            //   (city) => city?.key === data.arrivalIataCode
            // )}
            value={{
              value: data.arrivalIataCode,
              key: data.arrivalIataCode,
            }}
            defaultOptions={iataCodes}
            loadOptions={promiseCity}
            components={{ IndicatorSeparator: null }}
            onChange={(e) => onChangeSelect(e, keyIndex, "arrivalIataCode")}
            getOptionLabel={(opt) => opt.key || opt.label || opt.value}
            getOptionValue={(opt) => opt.value}
            styles={{
              control: () => ({
                backgroundColor: "#fff",
                minWidth: "280px",
                border: "1px solid rgba(229, 233, 235, 1)",
              }),
            }}
          />
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Arrival Date</p>
          <td className={styles.date_picker}>
            <DatePicker
              value={toSelectedDate}
              onChange={(e) => onChangeDate(e, keyIndex, "arrival_at")}
              inputPlaceholder="Arrival date"
              minimumDate={fromSelectedDate}
              colorPrimary="var(--primary-color)"
              wrapperClassName={styles.datepicker}
              shouldHighlightWeekends
            />
          </td>
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Arrival Time</p>

          <input
            type="time"
            className={styles.datetime}
            value={moment(data.arrival_at).format("HH:mm")}
            onChange={(e) => onChangeTime(e, keyIndex, "arrival_at")}
          />
        </div>

        <div className={styles.field}>
          <p className={styles.label}>Carrier</p>
          <TextField
            variant="outlined"
            placeholder="HY,TA"
            value={data.carrierCode}
            onChange={(e) => onChangeInput(e, keyIndex, "carrierCode")}
            fullWidth
            style={{
              background: "#fff",
              border: "0.1px solid rgba(229, 233, 235, 1)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const OneWayForm = ({ changeRouteField, routes }) => {
  const data = routes[0];

  return (
    <DefaultForm
      changeRouteField={changeRouteField}
      data={data}
      keyIndex={0}
      prevIndex={0}
    />
  );
};

const RoundTripForm = ({ changeRouteField, routes }) => {
  const data = routes.slice(0, 2);

  return (
    <div className={styles.round_trip}>
      {data.map((trip, idx) => (
        <>
          {idx === 0 ? <h4>Route 1</h4> : <h4>Return</h4>}
          <DefaultForm
            data={trip}
            keyIndex={idx}
            prevIndex={data.length - idx}
            changeRouteField={changeRouteField}
          />
        </>
      ))}
    </div>
  );
};

const DifficultRoute = ({
  changeRouteField,
  routes,
  addRoute,
  removeRoute,
}) => {
  return (
    <div className={styles.difficult_route}>
      {routes.map((route, r) => (
        <div
          className={styles.route}
          key={r}
          // style={{
          //   zIndex: 2,
          // }}
        >
          <DefaultForm
            data={route}
            changeRouteField={changeRouteField}
            keyIndex={r}
          />
          <Button
            variant="containedSecondary"
            className={styles.remove}
            onClick={() => (routes.length !== 1 ? removeRoute(r) : {})}
          >
            <DeleteOutlineOutlinedIcon />
          </Button>
        </div>
      ))}
      <Button
        variant="outlinedSecondary"
        onClick={addRoute}
        style={{
          zIndex: 0,
        }}
      >
        <AddOutlinedIcon />
        &nbsp;&nbsp; Add route
      </Button>
    </div>
  );
};

const FormRenderers = {
  [flightTypes.ONE_WAY]: OneWayForm,
  [flightTypes.ROUND_TRIP]: RoundTripForm,
  [flightTypes.DIFFICULT_ROUTE]: DifficultRoute,
};

const FlightForm = ({
  flightType,
  routes,
  changeRouteField,
  addRoute,
  changePrice,
  removeRoute,
}) => {
  const FormRenderer = FormRenderers[flightType];

  return (
    <div
      className={styles.form}
      style={
        {
          // overflowY: flightType === flightTypes.ONE_WAY ? "visible" : ""
        }
      }
    >
      <FormRenderer
        changeRouteField={changeRouteField}
        routes={routes}
        addRoute={addRoute}
        changePrice={changePrice}
        removeRoute={removeRoute}
      />
    </div>
  );
};

export default FlightForm;
