import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import styles from "./style.module.scss";
import { useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import EmailPqModal from "../../../OrderPopup/EmailPqModal";
import FlightPopup from "../Flights/FlightPopup";
import { useOutletContext } from "react-router-dom";
import ManualForm from "../Flights/FlightPopup/ManualForm";
import AddIcon from "@mui/icons-material/Add";
const FlightInfo = ({
  currentTicket,
  handleScreenshot,
  control,
  setIsModalOpen,
  isModalOpen,
  selectedFlight,
  setSelectedFlight,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const { isConfirmed, flights, addFlight, deleteFlight, changeFlight } =
    useOutletContext();

  const toggle = () => {
    setIsModalOpen((prev) => {
      if (prev) postAction();

      return !prev;
    });
  };
  const postAction = () => {
    setSelectedFlight(null);
    setSelectedIndex(null);
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={styles.flights_info}>
        <Typography variant="h4">Flight information</Typography>
        <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <Button onClick={toggle} variant="contained">
            <AddIcon fontSize="small" /> Manual Flight
          </Button>
          <Button
            onClick={handleOpen}
            disabled={!currentTicket}
            variant="contained"
          >
            Send PQ
          </Button>
        </Box>
        <Menu
          id="basic-menu"
          className="lead_menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            className={styles.item}
            onClick={() => {
              handleScreenshot("email");
              handleClose();
            }}
          >
            <EmailIcon /> Email
          </MenuItem>
          <MenuItem
            className={styles.item}
            onClick={() => {
              handleScreenshot("tg");
              handleClose();
            }}
          >
            <TelegramIcon /> Telegram
          </MenuItem>
        </Menu>
      </Box>
      {/* <EmailPqModal
        currentTicket={currentTicket}
        open={emailModalOpen}
        setOpen={setEmailModaOpen}
        control={control}
      /> */}
      <ManualForm
        key={"flight-popup" + isModalOpen}
        isOpen={isModalOpen}
        setSelectedFlight={setSelectedFlight}
        toggle={toggle}
        addFlight={addFlight}
        selectedFlight={selectedFlight}
        changeFlight={(data) => changeFlight(data, selectedIndex)}
        postAction={postAction}
      />
    </>
  );
};

export default FlightInfo;
