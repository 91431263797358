import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import InfoIcon from "@mui/icons-material/Info";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ManIcon from "@mui/icons-material/Man";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WomanIcon from "@mui/icons-material/Woman";
import { Box, Button, Popover, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { NumericFormat } from "react-number-format";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LeadStatusBackgroundColor,
  LeadStatusText,
  LeadStatusTextColor,
  leadStatus,
} from "../../consts/lead";
import useOrderAPI from "../../hooks/api/useOrderAPI";
import { copyNavigator, isUserAdmin, isUserSupervisor } from "../../utils";
import styles from "./style.module.scss";
import ReactCountryFlag from "react-country-flag";
import { ClockIcon } from "@mui/x-date-pickers";
import checkTime from "../../utils/checkTime";
import getHotelsTotalSum from "../../utils/useGetHotelsPirce";
const allData = require("airport-iata-codes/index");
const airports = require("airport-codes/airports.json");

const orderColumns = [
  {
    label: "Lead status",
    key: "lead_status",
    attributes: {
      width: 180,

      padding: "5px 10px",
    },
    render: ({ data }) => <LeadStatus data={data} />,
  },
  {
    label: "Passengers",
    key: "passengers",
    attributes: {
      width: 180,

      padding: "5px 10px",
    },
    render: ({ data }) => <PassengerColumn data={data} />,
  },
  {
    label: "Customer name",
    key: "customer",
    attributes: {
      width: 160,
      padding: "5px 10px",
    },
    render: ({ data }) => <LeadColumn data={data} />,
  },
  {
    label: "Agent",
    key: "user",
    attributes: {
      width: 180,
      padding: "5px 10px",
    },
    render: ({ data }) => <AgentColumn data={data} />,
  },
  {
    label: "Price",
    key: "price",
    attributes: {
      width: 160,
      padding: "5px 10px",
    },
    render: ({ data }) => <PriceColumn data={data} />,
  },
  // {
  //   label: "Extra info",
  //   key: "payment",
  //   attributes: {
  //     width: 100,
  //     padding: "5px 10px",
  //   },
  //   render: ({ data }) => (
  //     <Box display="flex" gap={1} justifyContent="center">
  //       <HotelIcon color={data.with_hotel ? "success" : "error"} />
  //       <DirectionsCarIcon color={data.with_transport ? "success" : "error"} />
  //     </Box>
  //   ),
  // },
];

export const FlightColumn = ({ data, onClick }) => {
  const flights = data.flights;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="2px"
      position="relative"
      justifyContent="flex-start"
      width="200px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          fontSize="15px"
          fontWeight={600}
          color="var(--primary-color)"
          style={{ cursor: "pointer" }}
          onClick={() => onClick(data)}
        >
          #{data.external_order_id}
        </Typography>

        <Typography fontSize="12px" color="var(--text-color)">
          🕐 {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </Box>

      <div className={styles.flight}>
        {flights?.map((flight, f) => {
          const info = JSON.parse(flight?.info);
          return (
            <Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              className={styles.flightContainer}
            >
              {info.itineraries.map((itinerary, i) =>
                itinerary.segments.map((segment, s) => (
                  <div className={styles.segment} key={s}>
                    <div className={styles.box}>
                      <p className={styles.iataCode}>
                        {segment.departure.iataCode}
                      </p>
                      {/* <p className={styles.at}>
                      ({moment(segment.departure.at).format("HH:mm")})
                    </p> */}
                    </div>
                    &nbsp;-&nbsp;
                    <div className={styles.box}>
                      <p className={styles.iataCode}>
                        {segment.arrival.iataCode}
                      </p>
                      {/* <p className={styles.at}>
                      ({moment(segment.arrival.at).format("HH:mm")})
                    </p> */}
                    </div>{" "}
                    {f === flights.length - 1 &&
                    i === info.itineraries.length - 1 ? (
                      <></>
                    ) : (
                      <>&nbsp;-&nbsp;</>
                    )}
                  </div>
                ))
              )}
            </Box>
          );
        })}
      </div>
    </Box>
  );
};

const PassengerColumn = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      {data.customers?.map((passenger, p) => (
        <div
          className={classNames(styles.passenger, {
            [styles.man]: passenger.gender === "male",
          })}
          key={p}
        >
          <Box display="flex" alignItems="center">
            {passenger.gender === "male" ? <ManIcon /> : <WomanIcon />}
            <p>{[passenger?.first_name, passenger?.last_name].join(" ")}</p>
          </Box>
          {JSON.parse(data?.customers_comments)?.find(
            (comment) => comment.customer_id === passenger.id
          )?.comment && (
            <div className={styles.popover}>
              <InfoIcon
                className={styles.popover_icon}
                style={{
                  color: "var(--primary-color)",
                }}
              />
              <div className={styles.popover_hover}>
                <p>
                  {
                    JSON.parse(data?.customers_comments)?.find(
                      (comment) => comment.customer_id === passenger.id
                    )?.comment
                  }
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};
const AgentColumn = ({ data }) => {
  const queryClient = useQueryClient();
  const { orderAssign } = useOrderAPI({});

  const assignAgentToOrder = (data) => {
    toast.promise(orderAssign.mutateAsync(data), {
      loading: "Assigning...",
      success: () => {
        queryClient.refetchQueries(["GET_ORDERS"]);
        return "Agent is assigned successful";
      },
      error: (err) => {
        return "Error on assigning agent!";
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" className={styles.agent}>
      {data?.assign_to ? (
        data?.created_by?.first_name === data?.assign_to?.first_name ? (
          <Typography
            fontWeight={600}
            ml={1}
            fontSize={10}
            className={styles.created}
          >
            {[data?.created_by?.first_name, data?.created_by?.last_name].join(
              " "
            )}
          </Typography>
        ) : (
          <Box className={styles.assigned}>
            <Typography fontWeight={600} ml={1} fontSize={10}>
              {`${[
                data?.created_by?.first_name,
                data?.created_by?.last_name,
              ].join(" ")} / ${[
                data?.assign_to?.first_name,
                data?.assign_to?.last_name,
              ].join(" ")}`}
            </Typography>
          </Box>
        )
      ) : (
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            assignAgentToOrder(data);
          }}
          className={styles.assign_button}
        >
          Assign
        </Button>
      )}
    </Box>
  );
};
const LeadColumn = ({ data }) => {
  return (
    <Box display="flex" alignItems="center" className={styles.agent}>
      <Typography fontWeight={600} ml={1} fontSize={10}>
        {[data.lead?.first_name, data.lead?.last_name].join(" ")}
      </Typography>
    </Box>
  );
};

export const CountryFlagColumn = ({ data }) => {
  const iataCode = allData(data?.lead?.location)[0];
  return (
    <>
      {iataCode ? (
        <ReactCountryFlag
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
          cdnSuffix="svg"
          countryCode={iataCode?.country_id}
          style={{
            fontSize: "32px",
            lineHeight: "32px",
            borderRadius: "50%",
          }}
        />
      ) : (
        "-"
      )}
    </>
  );
};

export const TimezoneByIataCode = ({ data }) => {
  const iataCode = airports?.find(
    (item) => item?.iata === data?.lead?.location
  );
  const check = checkTime(
    "8:00",
    "18:00",
    iataCode ? moment()?.tz(iataCode?.tz)?.format("HH:mm") : "-"
  );
  return (
    <>
      <Box className={classNames(styles.timezone, { [styles.active]: check })}>
        <ClockIcon />
        <Typography>{moment().tz(iataCode?.tz)?.format("HH:mm")}</Typography>
      </Box>
    </>
  );
};

export const LeadStatus = ({ data }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      justifyContent="center"
    >
      <div
        style={{
          background: LeadStatusBackgroundColor(data?.lead?.status),
          color: LeadStatusTextColor(data?.lead?.status),
        }}
        className={styles.lead_status}
      >
        {LeadStatusText(data?.lead?.status)}
      </div>
    </Box>
  );
};

const PriceColumn = ({ data }) => {
  const hotelSum = getHotelsTotalSum(data?.orderHotels, "grous_price");
  return (
    <Box display="flex" flexDirection="row" width="200px" flexWrap="wrap">
      {Number(data.payment.insurance_price) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            🆘
          </Typography>
          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={Number(data.payment.insurance_price)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
      {Number(data.payment.price) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            ✈️
          </Typography>

          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={Number(data.payment.price)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
      {Number(data.agent_tip) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            🥷
          </Typography>

          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={data.agent_tip || 0}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
      {Number(hotelSum) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            🏨
          </Typography>
          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={Number(hotelSum)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
      {Number(data?.payment?.package_price_gross) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            🏨✈️
          </Typography>
          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={Number(data?.payment?.package_price_gross)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
      {Number(data.payment.total) !== 0 && (
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <Typography fontSize={12} fontWeight={600} p={0} m={0}>
            💰
          </Typography>
          <Typography fontSize={10} fontWeight={600} p={0} m={0}>
            <NumericFormat
              value={Number(data.payment.total)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={3}
              prefix="$"
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export const OrderActionRenderer = ({ data, refetch }) => {
  const navigate = useNavigate();
  const { deleteOrder } = useOrderAPI({});
  const { user } = useSelector((store) => store.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    toast.promise(
      deleteOrder.mutateAsync(data.id, {
        onSuccess: refetch,
      }),
      {
        loading: "Deleting...",
        success: <b>Application deleted!</b>,
        error: <b>Error on deleting application.</b>,
      }
    );
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const copyVisible = !data.is_confirmed && data.customers?.length;
  const isSold = data?.lead?.status === leadStatus.SOLD;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <MoreHorizIcon
        aria-describedby={id}
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box width={120} display="flex" flexDirection="column">
          {!!data.contact_number && (
            <Button
              variant="outlinedSecondary"
              onClick={() => copyNavigator(data.contact_number, true)}
              style={{
                borderRadius: 0,
              }}
            >
              Number
              <ContentCopyIcon
                style={{
                  marginLeft: "10px",
                  fontSize: 16,
                }}
              />
            </Button>
          )}

          {!!data.contact_email && (
            <Button
              variant="outlinedSecondary"
              onClick={() => copyNavigator(data.contact_email, true)}
              style={{
                borderRadius: 0,
              }}
            >
              Email
              <ContentCopyIcon
                style={{
                  marginLeft: "10px",
                  fontSize: 16,
                }}
              />
            </Button>
          )}

          <Button
            variant="outlinedSecondary"
            onClick={() => copyNavigator(data.id)}
            style={{
              borderRadius: 0,
            }}
          >
            Copy
            <ContentCopyIcon
              style={{
                marginLeft: "10px",
                fontSize: 16,
              }}
            />
          </Button>

          {data?.lead?.status === "sold" ? (
            (isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
              <Button
                variant="outlinedSecondary"
                onClick={() => navigate(`${data.id}/lead`)}
                style={{
                  borderRadius: 0,
                }}
              >
                {isSold ? "Info" : "Edit"}
                <LaunchRoundedIcon
                  style={{
                    marginLeft: "10px",
                    fontSize: 16,
                  }}
                />
              </Button>
            )
          ) : (
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate(`${data.id}/lead`)}
              style={{
                borderRadius: 0,
              }}
            >
              {isSold ? "Info" : "Edit"}
              <LaunchRoundedIcon
                style={{
                  marginLeft: "10px",
                  fontSize: 16,
                }}
              />
            </Button>
          )}
          {(isUserAdmin(user.role) || isUserSupervisor(user.role)) &&
            (!data.is_confirmed || isUserSupervisor(user.role)) && (
              <Button
                variant="contained"
                style={{
                  borderRadius: 0,
                }}
                onClick={toggleModal}
              >
                Delete
                <DeleteOutlineRoundedIcon
                  style={{
                    marginLeft: "10px",
                    fontSize: 16,
                  }}
                />
              </Button>
            )}
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure to delete?
          </Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            gap="10px"
            mt="15px"
          >
            <Button
              variant="outlinedSecondary"
              onClick={handleDelete}
              style={{
                width: "50%",
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="containedSecondary"
              onClick={toggleModal}
              style={{
                width: "50%",
              }}
            >
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default orderColumns;
