import React from "react";
import styles from "./style.module.scss";

import { useSelector } from "react-redux";

function TicketHeader() {
  const { user } = useSelector((store) => store.auth);

  return (
    <header className={styles.ticketHeader}>
      <div className={styles.logoBlock}>
        <img src={"/ticket_logo.png"} alt="Trip Asia" />
      </div>

      <div className={styles.userBlock}>
        <div className={styles.avatarWrapper}>
          <img src={user?.image} alt="User avatar" />
          <span className={styles.onlineDot}></span>
        </div>
        <div className={styles.userInfo}>
          <h3>{`${user?.first_name} ${user?.last_name}`}</h3>
          <span>+1 (800) 763 9229</span>
        </div>
      </div>
    </header>
  );
}

export default TicketHeader;
