import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { DateRangePicker } from "rsuite";
import Accordion from "../../../components/Accordion";
import TSelect from "../../../components/Select";
import {
  predefinedFutureRanges,
  predefinedRanges,
} from "../../../consts/dates";
import { isUserAdmin, isUserSupervisor, stringAvatar } from "../../../utils";
import { getAgentsByName } from "../../../utils/getAgents";
import { promiseCity } from "../../../utils/getCities";
import OrderPopup from "../../Orders/OrderPopup";
import { CountryFlagColumn, TimezoneByIataCode } from "../../Orders/columns";
import { iataCodes } from "../../../consts/flights";
import NotFoundPage from "../../../components/NotFound";

const OrderStatsTable = ({
  user,
  users,
  filters,
  setTerm,
  data,
  onChangeAgentsFilter,
  onChangeDateFilter,
  onChangeIataCode,
  onClearFilter,
  onCleanDate,
  orderFilterDates,
  isLoading,
}) => {
  const [selectedOrder, setSelectedOrder] = useState();
  const [termSearch, setTermSearch] = useState("");

  const getIatas = (order, groupDate) => {
    const flights = order.flights;
    const iatas = [];
    flights.forEach((flight) =>
      JSON.parse(flight.info)?.itineraries?.forEach((itin) =>
        itin.segments.forEach((segm) => {
          iatas.push({
            segments: [segm.departure.iataCode, segm.arrival.iataCode],
            departureAt: segm.departure.at,
          });
        })
      )
    );

    return (
      <>
        {iatas?.map((iata, i) => {
          if (
            moment(iata.departureAt).format("DD MM yyyy") ===
            moment(groupDate).format("DD MM yyyy")
          )
            return (
              <span style={{ color: "red" }}>
                {iata.segments?.join(" - ")} {i !== iatas.length - 1 && " - "}
              </span>
            );

          return (
            <span>
              {iata.segments?.join(" - ")}
              {i !== iatas.length - 1 && " - "}{" "}
            </span>
          );
        })}
        &nbsp; &nbsp;
      </>
    );
  };

  const getFlightDates = (order, groupDate) => {
    const flights = [...order.flights];
    const dates = [];
    flights.forEach((flight) =>
      JSON.parse(flight.info)?.itineraries?.forEach((itin) =>
        itin.segments.forEach((segm) => {
          const currentDate = moment(segm.departure.at).format("yyyy-MM-DD");

          if (currentDate === groupDate)
            dates.push(segm.departure.at, segm.arrival.at);
        })
      )
    );

    return <>{moment(dates[0]).format("DD/MM/YY hh:mm A")}</>;
  };

  const handleTermChange = (e) => {
    setTerm(e);
    setTermSearch(e.target.value);
  };

  return (
    <>
      <Box display="flex" width="100%" flexDirection="column">
        <Box display="flex" alignItems="center" gap="10px">
          {(isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
            <TSelect
              isMulti={true}
              components={{
                DropdownIndicator: PersonRoundedIcon,
                IndicatorSeparator: null,
              }}
              value={users?.users?.rows?.filter((user) =>
                filters?.order_agents?.includes(user.id)
              )}
              loadOptions={getAgentsByName}
              defaultOptions={users?.users?.rows}
              getOptionLabel={(opt) => opt.first_name}
              onChange={onChangeAgentsFilter}
              getOptionValue={(opt) => opt.id}
              placeholder="Select agents"
              styles={{
                control: () => ({
                  minWidth: "280px",
                  maxWidth: "280px",
                }),
                container: (styles) => ({
                  ...styles,
                  minWidth: "280px",
                  maxWidth: "280px",
                }),
              }}
            />
          )}
          <TSelect
            placeholder="IATA Code"
            components={{ IndicatorSeparator: null }}
            value={
              filters?.iata_code
                ? {
                    value: filters?.iata_code,
                    key: filters?.iata_code,
                  }
                : null
            }
            loadOptions={promiseCity}
            defaultOptions={iataCodes}
            onChange={onChangeIataCode}
            getOptionLabel={(opt) => opt.key || opt.label || opt.value}
            getOptionValue={(opt) => opt.value}
            isClearable={true}
            styles={{
              control: () => ({
                minWidth: "280px",
                maxWidth: "280px",
              }),
              container: (styles) => ({
                ...styles,
                minWidth: "280px",
                maxWidth: "280px",
              }),
            }}
          />

          <Box width={"100%"} maxWidth={"250px"}>
            <DateRangePicker
              key={filters?.order_start_date + filters?.order_end_date}
              value={orderFilterDates}
              ranges={[...predefinedRanges, ...predefinedFutureRanges]}
              placeholder="Select dates"
              onClean={onCleanDate}
              onChange={onChangeDateFilter}
              placement="auto"
              preventOverflow
            />
          </Box>

          <TextField
            placeholder="Search by FIO/email"
            value={termSearch}
            onChange={handleTermChange}
            style={{
              width: 250,
              background: "#fff",
            }}
            className="input"
          />

          {(!!filters.order_agents ||
            !!filters.order_start_date ||
            !!filters.order_end_date ||
            !!filters.term ||
            !!filters.iata_code) && (
            <Button
              variant="outlinedSecondary"
              onClick={onClearFilter}
              style={{
                width: 200,
                height: "42px",
                whiteSpace: "nowrap",
                padding: "0 30px",
              }}
            >
              Clear Filter
            </Button>
          )}
        </Box>

        <Box display="flex" width="100%" flexDirection="column" mt={2}>
          {isLoading ? (
            <Box
              height={"100vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress size={40} />
            </Box>
          ) : (
            <>
              {!data.length && <NotFoundPage />}
              {data.map((item, i) => {
                return (
                  <Accordion
                    title={
                      <Badge
                        badgeContent={item.items.length}
                        color="secondary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {item.date}
                      </Badge>
                    }
                  >
                    <div>
                      {item.items.map((order) => {
                        return (
                          <Box
                            display="flex"
                            borderBottom="1px solid #e1e1e1"
                            width="100%"
                            alignItems="center"
                            padding={2}
                            columnGap={"4px"}
                          >
                            <Box width="7%">
                              <Typography
                                color="primary"
                                fontWeight={600}
                                onClick={() => setSelectedOrder(order)}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                #{order.external_order_id}
                              </Typography>
                            </Box>
                            <Box width="5%">
                              {order?.lead?.location && (
                                <CountryFlagColumn data={order} />
                              )}
                            </Box>

                            <Box width={"10%"}>
                              {order?.lead?.location && (
                                <TimezoneByIataCode data={order} />
                              )}
                            </Box>
                            <Box width="15%" maxWidth={"465px"}>
                              <Typography fontWeight={600}>
                                {getFlightDates(order, item?.realDate)}
                              </Typography>
                            </Box>
                            <Box width="20%">
                              <Typography fontWeight={600}>
                                {getIatas(order, item.realDate)}
                              </Typography>
                            </Box>
                            <Box width="15%">
                              <Typography
                                fontWeight={600}
                                display="flex"
                                alignItems="center"
                                gap={1}
                              >
                                <Avatar
                                  {...stringAvatar(
                                    [
                                      order.user.first_name,
                                      order.user.last_name,
                                    ].join(" ")
                                  )}
                                  sx={{
                                    ...stringAvatar(
                                      [
                                        order.user.first_name,
                                        order.user.last_name,
                                      ].join(" ")
                                    )?.sx,
                                    width: 32,
                                    height: 32,
                                    fontSize: 12,
                                  }}
                                />
                                {order.user.first_name}
                              </Typography>
                            </Box>
                            <Box width="25%">
                              <Typography
                                fontWeight={600}
                                display="flex"
                                alignItems="center"
                                gap={1}
                                flexWrap="wrap"
                              >
                                {order.customers.map((customer) => (
                                  <Avatar
                                    {...stringAvatar(
                                      [
                                        customer.first_name,
                                        customer.last_name,
                                      ].join(" ")
                                    )}
                                    sx={{
                                      ...stringAvatar(
                                        [
                                          customer.first_name,
                                          customer.last_name,
                                        ].join(" ")
                                      )?.sx,
                                      width: 32,
                                      height: 32,
                                      fontSize: 12,
                                    }}
                                  />
                                ))}
                              </Typography>
                            </Box>
                            <Box width="5%">
                              <Typography
                                fontWeight={600}
                                display="flex"
                                alignItems="center"
                                gap={1}
                              >
                                <NumericFormat
                                  value={order.price}
                                  displayType="text"
                                  decimalScale={2}
                                  thousandSeparator
                                  prefix="$"
                                />
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </div>
                  </Accordion>
                );
              })}
            </>
          )}
        </Box>
      </Box>

      <OrderPopup
        isOpen={!!selectedOrder}
        close={() => setSelectedOrder(null)}
        data={selectedOrder}
      />
    </>
  );
};

export default OrderStatsTable;
