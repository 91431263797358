import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { components } from "react-select";
import SelectAsync from "react-select/async";
import styles from "./style.module.scss";
import useHotelsAPI from "../../../../../../../hooks/api/useHotelsAPI";
import { customHotelStyles } from "../../../../../../../consts/lead";
import { HotelSearchIcon } from "../../../../../../../assets/icons";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { addSelectedHotel } from "../../../../../../../redux/hotel/hotelSlice";
import { promiseHotels } from "../../../../../../../utils/getHotels";
import { orderSections } from "../../../..";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const HotelSearch = ({ setType, selectedHotels }) => {
  const dispatch = useDispatch();
  const { id, orderId } = useParams();
  const navigate = useNavigate();
  const { data } = useHotelsAPI({
    enabled: true,
  });

  const { isConfirmed } = useOutletContext();

  const handleBack = () => {
    navigate(`../${orderSections.LEAD}`);
  };

  const onContinue = useCallback(() => {
    // if (selectedHotels?.length === 0) return toast.error("Add a hotel!");

    navigate(`../${orderSections.PAYMENTS}`);
  }, [isConfirmed, selectedHotels]);

  const hotelOptions = useMemo(() => {
    return data?.hotels?.rows?.map((item) => ({
      label: item?.name,
      value: item?.id,
      country: item?.region?.country?.name,
      region: item?.region?.name,
      data: { ...item },
    }));
  }, [data]);

  const formatOptionLabel = ({ value, label, country, region }) => (
    <Box display={"flex"} flexDirection={"column"}>
      <span
        style={{
          color: "#101828",
          fontWeight: 600,
        }}
      >
        {label}
      </span>
      <span style={{ color: "#475467" }}>{`${country}, ${region}`}</span>
    </Box>
  );

  const SelectContainer = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <HotelSearchIcon /> {children}
      </components.Control>
    );
  };

  const handleHotelSelect = (selectedOption) => {
    const hotelObject = {
      ...selectedOption,
      unique_id: Math.random().toString(),
      data: {
        ...selectedOption.data,
        country: selectedOption?.data?.region?.country,
        region: selectedOption?.data?.region,
      },
    };
    if (selectedOption) {
      dispatch(addSelectedHotel(hotelObject));

      if (orderId) {
        navigate(`/orders/${orderId}/hotels/${hotelObject.unique_id}`);
      } else {
        navigate(`/orders/create/hotels/${hotelObject.unique_id}`);
      }
    }
  };

  return (
    <Box className={styles.searchContainer}>
      {selectedHotels.length ? (
        <Box className={styles.backBtnWrapper}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            className={styles.backBtn}
            onClick={() => setType("list")}
          >
            Back
          </Button>
        </Box>
      ) : null}

      <Typography variant="h4" className={styles.searchTitle}>
        Hotel Search
      </Typography>

      <Box className={styles.searchInputWrapper}>
        <SelectAsync
          placeholder="Search"
          styles={customHotelStyles}
          // onInputChange={(e) => setSearch(e)}
          loadOptions={promiseHotels}
          // variant="outlined"
          defaultOptions={hotelOptions}
          className={styles.searchInput}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          formatOptionLabel={formatOptionLabel}
          // options={hotelOptions}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Control: SelectContainer,
          }}
          isClearable
          onChange={handleHotelSelect}
        />
      </Box>
      <Box
        position="absolute"
        left="-20px"
        bottom="-20px"
        width="calc(100% + 34px)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="white"
        height={56}
        padding="8px"
        style={{
          "box-shadow": " 0px 1px 0px 0px rgba(229, 233, 235, 1) inset",
        }}
      >
        {isConfirmed && (
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={onContinue}
          style={{ marginLeft: 12 }}
          className={styles.button}
        >
          {isConfirmed ? "Next" : "SAVE & CONTINUE"}
          <ArrowForwardIosOutlinedIcon
            style={{
              fontSize: "12px",
              marginLeft: 12,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default HotelSearch;
